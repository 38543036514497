import { ELikertColorSeverity, ELikertColorVariant, ELikertOptionPosition, ELikertOptionState } from "./likert.types"

function getLikertOptionState(isActionAffected: boolean, isSelected: boolean): ELikertOptionState {
    return isSelected
        ? ELikertOptionState.Selected
        : isActionAffected
          ? ELikertOptionState.ActionAffected
          : ELikertOptionState.Default
}

function getLikertOptionSeverity(colorVariant: ELikertColorVariant): ELikertColorSeverity {
    if (colorVariant === ELikertColorVariant.Default) return null // TBU once colored <Likerts/> are needed

    return null
}

function getLikertOptionPositionInList(iterationIdx: number, listLength: number): ELikertOptionPosition {
    return iterationIdx === 0
        ? ELikertOptionPosition.Left
        : listLength - 1 === iterationIdx
          ? ELikertOptionPosition.Right
          : ELikertOptionPosition.Middle
}

const utils = { getLikertOptionState, getLikertOptionSeverity, getLikertOptionPositionInList }

export { utils as likertUtils }
