import {
    type BaseSyntheticEvent,
    type Dispatch,
    type FC,
    type KeyboardEvent,
    type MouseEvent,
    type MutableRefObject,
    type ReactElement,
    type SetStateAction,
    useRef,
    useState
} from "react"

import classNames from "classnames"
import { CSSTransition } from "react-transition-group"

import {
    ESpecialChar,
    ETypographyAlignment,
    ETypographyColor,
    ETypographyFontWeight,
    ETypographySize,
    ETypographyTag,
    SpecialChars,
    Typography
} from "@/3514/components"
import { getAppearanceAnimationCssString } from "@/3514/utils"
import { type IUseTranslation, type TTranslationKeys, useCSSInsertion, useTranslation } from "@/hooks"
import type { TEmptyCallback } from "@/shared/types/functions"
import { CrossIconSvg } from "@/svgs/CrossIconSvg"

import { ParticipantCoachingMomentChatErrorIcon as ErrorIcon } from "../assets"
import { useParticipantCoachingMomentChatContext as useChatContext } from "../context"
import { EParticipantCoachingMomentChatErrorBoxVariant as EErrorVariant } from "../types"

type TProps = {
    variant?: EErrorVariant
}

const classes: {
    container(isApiError: boolean): string
    textWrapper(isApiError: boolean): string
    text: string
    textAction: string
    closeBtn: string
} = {
    container: (isApiError: boolean): string =>
        classNames(
            [
                "flex",
                "gap-x-[15px]",
                "relative",
                "pt-[15px]",
                "pr-[40px]",
                "pl-[20px]",
                "max-w-[580px]",
                "self-center",
                "my-[7px]", // double check component chat sizing, look repetitive
                "mx-auto",
                "w-full",
                "rounded-[10px]"
            ],
            isApiError ? "bg-gray-500 pb-[15px]" : "bg-red-300 pb-[20px]"
        ),
    textWrapper: (isApiError: boolean): string => classNames(isApiError ? "flex flex-col w-full" : "inline"),
    text: "inline",
    textAction: "underline underline-accent cursor-pointer",
    closeBtn: [
        "absolute",
        "p-[4px]",
        "top-[5px]",
        "right-[7px]",
        "rounded-full",
        "transition-all",
        "duration-150",
        "outline",
        "outline-[1px]",
        "outline-transparent",
        "hover:cursor-pointer",
        "hover:outline-gray-200",
        "focus:outline-gray-200"
    ].join(" ")
}

const titleTextsMap: { [E in EErrorVariant]: keyof TTranslationKeys } = {
    [EErrorVariant.OffTopic]: "participantSide.chapter.coachingMoment.chat.error.offTopic.title",
    [EErrorVariant.Api]: "participantSide.chapter.coachingMoment.chat.error.api.title"
}

const descriptionTextsMap: { [E in EErrorVariant]: keyof TTranslationKeys } = {
    [EErrorVariant.OffTopic]: "participantSide.chapter.coachingMoment.chat.error.offTopic.description",
    [EErrorVariant.Api]: "participantSide.chapter.coachingMoment.chat.error.api.description"
}

const Box: FC<TProps> = ({ variant = EErrorVariant.Api }: TProps): ReactElement => {
    const {
        chat: { hasError }
    }: ReturnType<typeof useChatContext> = useChatContext()

    const animationClassNameId: string = "pccmcer"

    useCSSInsertion({
        cssString: getAppearanceAnimationCssString({
            identifier: animationClassNameId,
            durationInMs: 150,
            scaleTo: 0.7
        })
    })

    const isApiError: boolean = variant === EErrorVariant.Api

    const { t }: IUseTranslation = useTranslation()

    const [isDiscarded, setIsDiscarded]: [boolean, Dispatch<SetStateAction<boolean>>] = useState<boolean>(false)

    const boxRef: MutableRefObject<HTMLDivElement> = useRef<HTMLDivElement>(null)

    const handleHideAction: (e?: BaseSyntheticEvent) => void = (e: KeyboardEvent & MouseEvent): void => {
        const action: TEmptyCallback = (): void => setIsDiscarded(true)

        if (e.type === "click") action()
        else if (e.key === "Enter") action()
    }

    return (
        <CSSTransition
            timeout={250}
            in={hasError && !isDiscarded}
            classNames={animationClassNameId}
            nodeRef={boxRef}
            mountOnEnter={hasError}
            unmountOnExit
        >
            <div ref={boxRef} className={classes.container(isApiError)}>
                {isApiError || <ErrorIcon />}

                <div className={classes.textWrapper(isApiError)}>
                    <Typography
                        className={classes.text}
                        alignment={isApiError ? ETypographyAlignment.Center : ETypographyAlignment.Left}
                        color={isApiError ? ETypographyColor.Error : ETypographyColor.Black}
                        size={ETypographySize.Small}
                        tag={ETypographyTag.Paragraph}
                        text={t(titleTextsMap[variant])}
                        weight={ETypographyFontWeight.Bold}
                    />
                    {isApiError || SpecialChars[ESpecialChar.EmptySpace]}
                    <Typography
                        className={classes.text}
                        alignment={isApiError ? ETypographyAlignment.Center : ETypographyAlignment.Left}
                        size={ETypographySize.Small}
                        tag={ETypographyTag.Span}
                        text={t(descriptionTextsMap[variant])}
                    />
                    {isApiError || SpecialChars[ESpecialChar.EmptySpace]}
                    {isApiError || (
                        <div className={classes.text} role="link" tabIndex={0}>
                            <Typography
                                className={classNames(classes.text, classes.textAction)}
                                size={ETypographySize.Small}
                                tag={ETypographyTag.Span}
                                text={t("participantSide.chapter.coachingMoment.chat.error.offTopic.action")}
                                color={ETypographyColor.Accent}
                            />
                        </div>
                    )}
                </div>

                <div
                    role="button"
                    aria-label={t("Close button")}
                    className={classes.closeBtn}
                    onClick={handleHideAction}
                    onKeyDown={handleHideAction}
                    tabIndex={0}
                >
                    <CrossIconSvg width={10} height={10} strokeColor="#333333" />
                </div>
            </div>
        </CSSTransition>
    )
}

Box.displayName = "ParticipantCoachingMomentChatErrorBox"

export { Box as ParticipantCoachingMomentChatErrorBox }
