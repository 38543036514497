import { type ComponentType, type LazyExoticComponent, lazy } from "react"

import { type TParticipantHeaderEntityProps } from "./participant-header.entity"

const ParticipantHeaderEntity: LazyExoticComponent<ComponentType<TParticipantHeaderEntityProps>> = lazy(
    (): Promise<{ default: ComponentType<TParticipantHeaderEntityProps> }> =>
        import("./participant-header.entity").then(
            ({ ParticipantHeaderEntity: E }): { default: ComponentType<TParticipantHeaderEntityProps> } => ({
                default: E
            })
        )
)

export { ParticipantHeaderEntity, type TParticipantHeaderEntityProps }
