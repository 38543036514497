import { FC, ReactNode, useMemo } from "react"

import classNames from "classnames"
import { Link } from "react-router-dom"

import useMediaQuery from "$/hooks/use-media-query"

import type { IParticipantModel } from "@/adapters"
import { MAX_WIDTH_MOBILE_MEDIA } from "@/constants"
import { type IAuthContext, useAuthContext } from "@/context"
import ParticipantLayoutHeader from "@/elements/participant-layout-header/ParticipantLayoutHeader"
import UserHeaderDropdown from "@/elements/participant-layout-header/UserHeaderDropdown"
import { type IUseTranslation, type TUseLogoNavigation, useLogoNavigation } from "@/hooks"
import { useTranslation } from "@/hooks"
import { LocalStorageServiceKeys, StorageService } from "@/services"
import ProductTypeLogo from "@/shared/ProductTypeLogo"
import BackButton from "@/shared/back-btn/BackButton"

import { ViewAsBanner } from "./components"

import "./styles.scss"

interface IProps {
    header?: ReactNode
    pageTitle?: ReactNode
    withProfileHeader?: boolean
    withoutHeader?: boolean
    headerVariant?: "default" | "gray"
    showBackBtn?: boolean
    showProfilePhoto?: boolean
    isSimpleHeader?: boolean
    showAvatarOnMobile?: boolean
    withFooter?: boolean
    children: ReactNode
    withUserDropdownWithoutMargin?: boolean
    className?: string
}

const storageService: StorageService = new StorageService()

const SimpleLayout: FC<IProps> = ({
    children,
    header = null,
    pageTitle = null,
    withProfileHeader = false,
    withoutHeader = false,
    headerVariant = "default",
    showBackBtn = false,
    isSimpleHeader,
    showAvatarOnMobile = false,
    withFooter = true,
    showProfilePhoto = false,
    withUserDropdownWithoutMargin = false,
    className = ""
}) => {
    const { t }: IUseTranslation = useTranslation()
    const { logout, user }: IAuthContext = useAuthContext()
    const isMobile: boolean = useMediaQuery(MAX_WIDTH_MOBILE_MEDIA)

    const handleLogout = async e => {
        e.preventDefault()

        logout({
            keepLocalStorageAuthTokens: (user as IParticipantModel)?.isFakeParticipant,
            onComplete: async (): Promise<void> =>
                user?.hasSubUsers
                    ? storageService.setItem<boolean>(LocalStorageServiceKeys.MainUserLoggedOut(), true)
                    : void 0
        })
    }

    const { handleLogoNavigation, logoNavigationUrl }: TUseLogoNavigation = useLogoNavigation()

    const navItem = useMemo(() => {
        if (isSimpleHeader) {
            return <ProductTypeLogo width={26} heigth={34} handleLogoClick={handleLogoNavigation} />
        }

        if (withProfileHeader) {
            return <ParticipantLayoutHeader withBurgerMenu={false} header={header} fullWidth showLogo={false} />
        }

        if ((showProfilePhoto && !isMobile) || (showProfilePhoto && showAvatarOnMobile)) {
            return (
                <UserHeaderDropdown
                    handleLogout={handleLogout}
                    user={user}
                    className={classNames("simple-layout-user-dropdown", {
                        "ml-auto": !withProfileHeader && !withUserDropdownWithoutMargin
                    })}
                />
            )
        }

        if (!showProfilePhoto && (!withProfileHeader || isMobile)) {
            return (
                <div>
                    <a href="#" className="text-dark" onClick={handleLogout}>
                        {t("Sign Out")}
                    </a>
                </div>
            )
        }
    }, [withProfileHeader, showProfilePhoto, showAvatarOnMobile, isSimpleHeader, isMobile])

    return (
        <div className={classNames("wrapper flex-column", { [className]: className })}>
            {(user as IParticipantModel)?.isFakeParticipant && <ViewAsBanner />}
            <header className={classNames("header-sticky", { "bg-simple-gray": headerVariant === "gray" })}>
                <div className="container">
                    <nav className={classNames("navbar navbar-expand-lg navbar-simple")}>
                        {!withProfileHeader && !withoutHeader && (
                            <Link to={logoNavigationUrl} className="home-link d-none-mobile">
                                <ProductTypeLogo width={26} heigth={34} />
                            </Link>
                        )}
                        {header && <span className="header-center header-center-mobile mx-auto">{header}</span>}
                        {navItem}
                    </nav>
                </div>
            </header>
            <main className="main w-100">
                <div className="container">
                    {pageTitle && (
                        <div className="page-title">
                            <h1 className="h1 font-extrabold text-center mb-6">{pageTitle}</h1>
                        </div>
                    )}
                    {children}
                </div>
            </main>
            {withFooter && <footer className="footer" />}
            {showBackBtn && <BackButton className="color-brand back-btn-float" />}
        </div>
    )
}

export default SimpleLayout
