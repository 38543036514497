import { useSelector } from "react-redux"

import type { IModal, TModalProps } from "../slices"
import type { IRootState } from "../store"

const selectModals: (state: IRootState) => IModal[] = (state: IRootState): IModal[] => state.modal.modals

function useModalSelector(): IModal<TModalProps>[] {
    return useSelector(selectModals)
}

export { useModalSelector }
