import { useCallback } from "react"

import { useDispatch } from "react-redux"

import type { IAppDispatch } from "@/3514/store"
import { useModalSelector } from "@/3514/store/selectors"
import {
    EModalId,
    type IModal,
    type TModalProps,
    hideAllModals as hideAllModalsAction,
    hideModal as hideModalAction,
    showModal as showModalAction
} from "@/3514/store/slices"
import type { TEmptyCallback } from "@/shared/types/functions"

type TUseModal = {
    showModal(id: EModalId, modalProps?: TModalProps): void
    hideModal(id: EModalId): void
    hideAllModals: TEmptyCallback
    getModal?<T>(modalId: EModalId): IModal<T>
}

function useModal(): ReturnType<() => TUseModal> {
    const dispatch: IAppDispatch = useDispatch<IAppDispatch>()

    const modals: IModal[] = useModalSelector()

    const showModal: (id: EModalId, modalProps: TModalProps) => void = useCallback(
        (id: EModalId, modalProps: TModalProps): void => (dispatch(showModalAction({ id, modalProps })), void 0),
        [dispatch]
    )

    const hideModal: (modalId: EModalId) => void = useCallback(
        (id: EModalId): void => (dispatch(hideModalAction({ id })), void 0),
        [dispatch]
    )

    const hideAllModals: TEmptyCallback = useCallback(
        (): TEmptyCallback => (dispatch(hideAllModalsAction({})), void 0),
        [dispatch]
    )

    const getModal: <T>(id: EModalId) => IModal<T> | undefined = useCallback(
        <T>(id: string): IModal<T> | undefined =>
            modals.find((modal: IModal): boolean => modal.id === id) as IModal<T> | undefined,
        [modals]
    )

    return { showModal, hideModal, hideAllModals, getModal }
}

export { useModal, type TUseModal }
