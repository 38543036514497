import { type FC, type ReactElement } from "react"

import classNames from "classnames"
import { type Params, useParams } from "react-router-dom"

import { isEmpty } from "$/utils/gates"

import { ESpinnerSize, LoadableComponent, Spinner } from "@/3514/components"
import { type IAuthContext, useAuthContext } from "@/context"
import { type TModuleByIdQuery, useModuleByIdQuery } from "@/pages/participant-dashboard/api"

import { ParticipantDashboardModulePhases as Phases, ParticipantDashboardModuleTitle as Title } from "./components"

const classes: { section(isDataReady: boolean): string; container: string } = {
    section: (isDataReady: boolean): string =>
        classNames(
            "max-w-[800px] w-full mx-auto max-[767px]:pt-[15px] max-[767px]:px-[20px]",
            isDataReady && "mb-[40px]"
        ),
    container: "max-w-[560px] w-full mx-auto"
}

/**
 * ParticipantDashboard module. Design: {@link https://www.figma.com/design/J6qF3Pa6zeK3udtVWLskjd/Participant?node-id=13088-96022&m=dev}
 * @return ReactElement
 */
const Component: FC = (): ReactElement => {
    const { moduleId }: Readonly<Params> = useParams()

    const { user }: IAuthContext = useAuthContext()

    const userModuleByParamsModuleId: number = user.modules.find(({ rank }): boolean => rank === +moduleId)?.id

    const { data: moduleData, isFetching: isModuleDataFetching }: TModuleByIdQuery = useModuleByIdQuery({
        enabled: !isEmpty(userModuleByParamsModuleId),
        id: userModuleByParamsModuleId
    })

    const isModuleDataReady: boolean = !isEmpty(moduleData)

    return (
        <section className={classes.section(isModuleDataReady)}>
            <LoadableComponent
                isLoading={isModuleDataFetching}
                isReady={isModuleDataReady}
                loadingFallback={<Spinner size={ESpinnerSize.Small} className="mt-[10px] mb-[40px]" />}
                component={<Title title={moduleData?.title} rank={moduleData?.rank} />}
            />
            <LoadableComponent
                isLoading={isModuleDataFetching}
                isReady={isModuleDataReady}
                loadingFallback={<Spinner size={ESpinnerSize.Large} />}
                component={<Phases phases={moduleData?.phases} />}
            />
        </section>
    )
}

Component.displayName = "ParticipantDashboardModuleEntity"

export { Component as ParticipantDashboardModuleEntity }
