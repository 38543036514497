import { getCurrentAccessToken, getCurrentRefreshToken } from "$/utils/tokens"

import { Role } from "@/constants"
import User from "@/models/user"
import { LocalStorageServiceKeys as StorageKey, StorageService, type TStoredSubUser } from "@/services"

interface IParticipantModel extends User {
    // TODO: TD - extend later
    isFakeParticipant?: boolean
}

interface ICoachModel extends User {}

interface IProgramManagerModel extends User {}

type TUserModel = IParticipantModel | ICoachModel | IProgramManagerModel

type TAdaptUserFunction = (user: User) => TUserModel

const storageService: StorageService = new StorageService()

const participantAdapter: TAdaptUserFunction = (user: User): IParticipantModel => ({
    ...user,
    isFakeParticipant: Boolean(storageService.getItem<TStoredSubUser[]>(StorageKey.SubUserList()))
})

const coachAdapter: TAdaptUserFunction = (user: User): ICoachModel => user // TODO: TD - extend later
const programManagerAdapter: TAdaptUserFunction = (user: User): IProgramManagerModel => user // TODO: TD - extend later

const jwtAdapter: TAdaptUserFunction = (user: User): User => ({
    ...user,
    jwtPair: {
        access: user?.jwtPair?.access || getCurrentAccessToken(),
        refresh: user?.jwtPair?.refresh || getCurrentRefreshToken()
    }
})

const userAdapter: (user: User) => TUserModel = (user: User): TUserModel => {
    switch (user.role) {
        case Role.Participant:
            return participantAdapter(user)
        case Role.Coach:
            return coachAdapter(user)
        case Role.ProgramManager:
            return programManagerAdapter(user)
        default:
            return user
    }
}

const carryingAdapter =
    (_jwtAdapter: TAdaptUserFunction) =>
    (_userAdapter: TAdaptUserFunction) =>
    (user: User): TUserModel =>
        _userAdapter(_jwtAdapter(user))

const adaptUser: (user: User) => TUserModel = carryingAdapter(jwtAdapter)(userAdapter)

export { adaptUser, type IParticipantModel, ICoachModel, IProgramManagerModel, TUserModel }
