import type { FC, ReactElement, ReactNode } from "react"

import classNames from "classnames"
import ReactTooltip from "react-tooltip"

import { ETypographyAlignment, ETypographySize, ETypographyTag, Typography } from "@/3514/components"

import { EVerticalProgressBarTooltipPlacement as EPlacement } from "./vertical-progress-bar.types"

type TProps = { text: string | ReactNode; placement: EPlacement; barId: string }

const classes: { tooltip(): string } = {
    tooltip: (): string => classNames("!rounded-[5px] !border-[1px] after:!hidden before:!hidden !p-[0px]")
}

const Component: FC<TProps> = ({ text, placement, barId }: TProps): ReactElement => (
    <ReactTooltip
        id={barId}
        className={classes.tooltip()}
        arrowColor="transparent"
        border
        borderColor="#E3E3E3"
        backgroundColor="#fff"
        effect="solid"
        place={placement === EPlacement.Center ? "bottom" : "left"}
        {...(placement === EPlacement.Center && { offset: { top: 110 } })}
    >
        <Typography
            className="px-[7px] py-[5px]"
            text={text}
            alignment={ETypographyAlignment.Center}
            size={ETypographySize.Small}
            tag={ETypographyTag.Paragraph}
        />
    </ReactTooltip>
)

Component.displayName = "VerticalProgressBarTooltip"

export { Component as VerticalProgressBarTooltip, type TProps as TVerticalProgressBarTooltipProps }
