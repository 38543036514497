import type { FC, ReactElement } from "react"

import sanitizeHtml from "sanitize-html"

type TProps = { content: string }

const BasicRenderer: FC<TProps> = ({ content = String() }: TProps): ReactElement => (
    <div dangerouslySetInnerHTML={{ __html: sanitizeHtml(content) }} />
)

export { BasicRenderer }
