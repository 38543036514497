import {
    type ChangeEvent,
    type ForwardRefExoticComponent,
    type ForwardedRef,
    type KeyboardEvent,
    type PropsWithoutRef,
    type ReactElement,
    type RefAttributes,
    forwardRef
} from "react"

import classNames from "classnames"

import type { TEmptyCallback } from "@/shared/types/functions"

import { ETypographyColor, ETypographySize } from "../typography"

import { textareaConfig } from "./textarea.config"
import { ETextAreaVariant } from "./textarea.types"

type TProps = {
    variant?: ETextAreaVariant
    defaultValue?: string
    textColor?: ETypographyColor
    textSize?: ETypographySize
    placeholderTextColor?: ETypographyColor
    placeholderTextSize?: ETypographySize
    value?: string
    onChange?(event?: ChangeEvent<HTMLTextAreaElement>): void
    onKeyDown?(event?: KeyboardEvent): void
    onFocus?: TEmptyCallback
    onBlur?: TEmptyCallback
    placeholder?: string
    minLines?: number
    className?: string
    isDisabled?: boolean
    withResizeDisabled?: boolean
}

const Component: ForwardRefExoticComponent<PropsWithoutRef<TProps> & RefAttributes<HTMLTextAreaElement>> = forwardRef<
    HTMLTextAreaElement,
    TProps
>(
    (
        {
            variant = ETextAreaVariant.Default,
            value,
            textColor = ETypographyColor.Dark,
            textSize = ETypographySize.Medium,
            placeholderTextColor = ETypographyColor.DarkGray,
            placeholderTextSize = ETypographySize.Medium,
            defaultValue,
            onChange,
            onKeyDown,
            onFocus,
            onBlur,
            placeholder,
            minLines = 2,
            className = String(),
            isDisabled = false,
            withResizeDisabled = false,
            ...rest
        }: TProps,
        ref: ForwardedRef<HTMLTextAreaElement>
    ): ReactElement => (
        <textarea
            {...rest}
            ref={ref}
            tabIndex={0}
            onKeyDown={onKeyDown}
            aria-disabled={isDisabled}
            disabled={isDisabled}
            className={classNames({
                "resize-none": withResizeDisabled,
                [className]: className,
                [textareaConfig.variantClassesMap[variant](
                    textSize,
                    textColor,
                    isDisabled,
                    placeholderTextColor,
                    placeholderTextSize
                )]: variant
            })}
            defaultValue={defaultValue}
            value={value}
            placeholder={placeholder}
            rows={minLines}
            onChange={onChange}
            onFocus={onFocus}
            onBlur={onBlur}
        />
    )
)

Component.displayName = "Textarea"

export { Component as TextArea }
