import { type Dispatch, type MutableRefObject, type SetStateAction, useEffect, useRef, useState } from "react"

import { LocalStorageServiceKeys, StorageService, TStoredSubUser } from "@/services"
import type { TEmptyCallback } from "@/shared/types/functions"

type TUseHook = {
    subUserList: TStoredSubUser[]
    isSubUserListLoading: boolean
}

const storageService: StorageService = new StorageService()

function useHook(this: { msDebounce: number }): ReturnType<() => TUseHook> {
    const [subUserList, setSubUserList]: [TStoredSubUser[], Dispatch<SetStateAction<TStoredSubUser[]>>] = useState<
        TStoredSubUser[]
    >([])
    const loadingRef: MutableRefObject<boolean> = useRef<boolean>(true)

    useEffect((): TEmptyCallback => {
        const interval: ReturnType<typeof setInterval> = setInterval((): void => {
            const storedList: TStoredSubUser[] = storageService.getItem<TStoredSubUser[]>(
                LocalStorageServiceKeys.SubUserList()
            )

            if (storedList) {
                loadingRef.current = false
                setSubUserList(storedList)
                clearInterval(interval)
            }
        }, this.msDebounce)

        return (): void => clearInterval(interval)
    }, [])

    return { subUserList, isSubUserListLoading: loadingRef.current }
}

export { useHook as useSubUserList, TUseHook as TUseSubUserList }
