import { isEmpty } from "$/utils/gates"

import type {
    IParticipantCoachingMomentChatNewMessagesDto as IDto,
    IParticipantCoachingMomentChatMessageDto as IMessageDto,
    IParticipantCoachingMomentChatMessageModel as IMessageModel,
    IParticipantCoachingMomentChatNewMessagesModel as IModel
} from "../../../types"
import { participantCoachingMomentChatApiUtils } from "../../../utils"

const { getChatMessageModelFromDto } = participantCoachingMomentChatApiUtils

function adapter(dto: IDto): IModel {
    return isEmpty(dto)
        ? null
        : {
              errors: dto.errors,
              messages: isEmpty(dto.messages)
                  ? []
                  : dto.messages.map((msg: IMessageDto): IMessageModel => getChatMessageModelFromDto(msg))
          }
}

export { adapter as coachingMomentChatNewMessagesQueryAdapter }
