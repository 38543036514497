import { EVideoRendererSourceProvider } from "./video-renderer.types"

function getVideoLinkByProvider(videoId: string, provider: EVideoRendererSourceProvider): string {
    switch (provider) {
        case EVideoRendererSourceProvider.Vimeo:
        default:
            return `https://vimeo.com/${videoId}`
    }
}

const utils = { getVideoLinkByProvider }

export { utils as videoRendererUtils }
