import {
    type Context,
    type Dispatch,
    type FC,
    type ReactElement,
    type ReactNode,
    type SetStateAction,
    createContext,
    useContext,
    useEffect,
    useMemo,
    useState
} from "react"

import { yupResolver } from "@hookform/resolvers/yup"
import { type UseFormReturn, useForm, useWatch } from "react-hook-form"

import { type TEmptyAsyncCallback, emptyAsyncCallback } from "@/shared/types/functions"

import { Likert } from "./likert.component"
import { getLikertFormSchema as getSchema } from "./likert.schema"
import { ELikertColorVariant, ELikertOptionSize, type TLikertQuestion } from "./likert.types"

enum ELikertFormField {
    QuestionList = "questionList"
}

interface ILikertFormQuestion {
    answer: number
    index: number
    id: number
    freeResponsePrompt: string | undefined
    isNotApplicable: boolean
}

interface ILikertFormData {
    questionList: ILikertFormQuestion[]
}

interface ILikertProviderProps {
    title: string
    questionList: TLikertQuestion[]
    colorVariant?: ELikertColorVariant
    optionSize?: ELikertOptionSize
    isRequired?: boolean
    onAllOptionsSelection?: TEmptyAsyncCallback
    renderCompletionStatus?(isCompleted: boolean): ReactNode
    renderSummary?(isCompleted: boolean): ReactNode
}

type ILikertContextType = {
    formMethods: UseFormReturn<ILikertFormData>
} & Pick<ILikertProviderProps, "colorVariant" | "optionSize">

const LikertContext: Context<ILikertContextType> = createContext<ILikertContextType | undefined>(undefined)

const LikertProvider: FC<ILikertProviderProps> = ({
    title,
    questionList,
    isRequired,
    onAllOptionsSelection = emptyAsyncCallback,
    optionSize = ELikertOptionSize.FullWidth,
    colorVariant = ELikertColorVariant.Default,
    renderCompletionStatus = undefined,
    renderSummary = undefined
}: ILikertProviderProps): ReactElement => {
    const [isFormSubmitted, setIsFormSubmitted]: [boolean, Dispatch<SetStateAction<boolean>>] = useState<boolean>(false)

    const sortedList: TLikertQuestion[] = questionList.sort(
        (a: TLikertQuestion, b: TLikertQuestion): number => a.order - b.order
    )

    const formMethods: UseFormReturn<ILikertFormData> = useForm<ILikertFormData>({
        reValidateMode: "onChange",
        resolver: yupResolver(getSchema({ isRequired })),
        defaultValues: {
            [ELikertFormField.QuestionList]: sortedList.map(
                ({ id, isNotApplicable: _isNotApplicable }: TLikertQuestion, index: number): ILikertFormQuestion => ({
                    id,
                    index,
                    answer: 0,
                    isNotApplicable: false,
                    freeResponsePrompt: String()
                })
            )
        }
    })

    const watchedAnswers: ILikertFormQuestion[] = useWatch({
        control: formMethods.control,
        name: ELikertFormField.QuestionList
    })

    const isAllOptionsFilled: boolean = useMemo(
        (): boolean =>
            watchedAnswers
                .filter(({ isNotApplicable }: ILikertFormQuestion): boolean => !isNotApplicable)
                .every((q: ILikertFormQuestion): boolean => q.answer !== 0),
        [watchedAnswers]
    )

    useEffect((): void => {
        if (isAllOptionsFilled) {
            formMethods
                .trigger()
                .then(
                    async (isValid: boolean): Promise<void> =>
                        isValid ? (setIsFormSubmitted(true), onAllOptionsSelection()) : void 0
                )
        } else {
            setIsFormSubmitted(false)
        }
    }, [isAllOptionsFilled, formMethods, onAllOptionsSelection])

    const value: ILikertContextType = useMemo(
        (): ILikertContextType => ({
            formMethods,
            optionSize,
            colorVariant
        }),
        [colorVariant, formMethods, optionSize]
    )

    return (
        <LikertContext.Provider value={value}>
            <Likert
                title={title}
                questionList={sortedList}
                renderCompletionStatus={(): ReactNode => renderCompletionStatus?.(isFormSubmitted)}
                renderSummary={(): ReactNode => renderSummary?.(isFormSubmitted)}
            />
        </LikertContext.Provider>
    )
}

function useLikertContext(): ILikertContextType {
    const context: ILikertContextType = useContext(LikertContext)

    if (!context) {
        console.log("useLikert must be used within a LikertProvider")
    }

    return context
}

export { LikertProvider, useLikertContext, ELikertFormField }
export type { ILikertContextType, ILikertFormData, ILikertFormQuestion }
