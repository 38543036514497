import {
    type Dispatch,
    type FC, // type LazyExoticComponent,
    type ReactElement,
    type SetStateAction,
    Suspense, // lazy,
    startTransition,
    useCallback,
    useDeferredValue,
    useMemo,
    useState
} from "react"

import { type NavigateFunction, type Params, useNavigate, useParams } from "react-router-dom"

import useMediaQuery from "$/hooks/use-media-query"
import { isNullOrUndefined } from "$/utils/gates"

import {
    type TUseAvailableGuestSessionsMutation,
    useAvailableGuestSessionsMutation
} from "@/api/mutations/use-available-guest-sessions-mutation"
import useCoachingSessions from "@/api/use-coaching-sessions"
import { MAX_WIDTH_MOBILE_MEDIA } from "@/constants"
import { type IUseTranslation, useTranslation } from "@/hooks"
import {
    type IAvailableGuestSessionModel,
    type TAvailableGuestSessionsQuery,
    useAvailableGuestSessionsQuery
} from "@/pages/swap-session/api"
import CareTeamLink from "@/shared/CareTeamLink"
import { FullSpinner } from "@/shared/spinner"
import type { TEmptyCallback } from "@/shared/types/functions"

import type { IOriginalSession } from "./types"

import {
    SwapSessionConfirmSwapModal as ConfirmSwapModal,
    SwapSessionHeading as Heading,
    SwapSessionErrorMessage as Message,
    SwapSessionNASessionsBlock as NoSessionBlock,
    SwapSessionSessionBlock as SessionBlock,
    SwapSessionSlider as Slider,
    type TSwapSessionConfirmSwapModalSubmitFunction as TConfirmSwapModalSubmitFunction // type TSwapSessionSessionBlockProps as TSessionBlockProps,
    // type TSwapSessionSliderProps as TSliderProps
} from "."

// const SessionBlock: LazyExoticComponent<FC<TSessionBlockProps>> = lazy(
//     (): Promise<{ default: FC<TSessionBlockProps> }> => import("./SwapSessionSessionBlock")
// )
// const Slider: LazyExoticComponent<FC<TSliderProps>> = lazy(
//     (): Promise<{ default: FC<TSliderProps> }> => import("./SwapSessionSlider")
// )

const SwapSessionContainer: FC = (): ReactElement => {
    const { t }: IUseTranslation = useTranslation()

    const isMobile: boolean = useMediaQuery(MAX_WIDTH_MOBILE_MEDIA)

    const navigate: NavigateFunction = useNavigate()

    const { sessionId }: Readonly<Params> = useParams()

    const { data: coachingSessions, isLoading: areCoachingSessionLoading } = useCoachingSessions({
        enabled: !!sessionId
    })

    const originalSession: IOriginalSession | undefined = useMemo(
        (): IOriginalSession | undefined =>
            coachingSessions?.upcoming_sessions?.find(
                (s: { session_id: number }): boolean => s?.session_id === +sessionId
            ),
        [coachingSessions?.upcoming_sessions, sessionId]
    )

    const {
        data: availableSessionsData,
        isError: isErrorWithAvailableSessionsData,
        isLoading: areAvailableSessionsLoading,
        error: availableSessionsError
    }: TAvailableGuestSessionsQuery = useAvailableGuestSessionsQuery({
        id: +sessionId,
        enabled: !!sessionId
    })

    const availableSessions: IAvailableGuestSessionModel[] =
        useDeferredValue<IAvailableGuestSessionModel[]>(availableSessionsData)

    const [isSwapSessionModalOpen, setIsSwapSessionModalOpen]: [boolean, Dispatch<SetStateAction<boolean>>] =
        useState<boolean>(false)

    const [isSessionConfirmed, setIsSessionConfirmed]: [boolean, Dispatch<SetStateAction<boolean>>] =
        useState<boolean>(false)

    const [suggestedSession, setSuggestedSession]: [
        IAvailableGuestSessionModel,
        Dispatch<SetStateAction<IAvailableGuestSessionModel>>
    ] = useState<IAvailableGuestSessionModel>()

    const handleCloseSwapSessionModal: TEmptyCallback = useCallback(
        (): void =>
            startTransition(
                (): void => (
                    setIsSwapSessionModalOpen(false),
                    setSuggestedSession(undefined),
                    setIsSessionConfirmed(false),
                    navigate(-1)
                )
            ),
        [navigate]
    )

    const handleOpenSwapSessionModal: TEmptyCallback = useCallback(
        (): void => startTransition((): void => setIsSwapSessionModalOpen(true)),
        []
    )

    const handleSetSuggestedSession: (session: IAvailableGuestSessionModel) => void = useCallback(
        (session: IAvailableGuestSessionModel): void => setSuggestedSession(session),
        []
    )

    const {
        isSuccess: isSwapMutationSuccessful,
        error: swapMutationError,
        mutateAsync: handleSwapAvailableSessionMutation
    }: TUseAvailableGuestSessionsMutation = useAvailableGuestSessionsMutation()

    const handleSubmitSwapSessionsClick: TConfirmSwapModalSubmitFunction = useCallback(
        async ({ suggestedSessionId }: { suggestedSessionId: number }): Promise<void> =>
            isSessionConfirmed
                ? handleCloseSwapSessionModal()
                : (await handleSwapAvailableSessionMutation(
                      { originalSessionId: originalSession?.session_id, swappedSessionId: suggestedSessionId },
                      {
                          onSuccess: () => startTransition(() => setIsSessionConfirmed(true))
                      }
                  ),
                  void 0),
        [
            isSessionConfirmed,
            handleCloseSwapSessionModal,
            handleSwapAvailableSessionMutation,
            originalSession?.session_id
        ]
    )

    function renderOriginalSessionBlock(): ReactElement {
        return (
            <Suspense fallback={<FullSpinner />}>
                {areCoachingSessionLoading ? (
                    <FullSpinner />
                ) : (
                    !isNullOrUndefined(originalSession) && (
                        <SessionBlock
                            isOriginal
                            coachPhoto={originalSession?.coach?.photo}
                            coachName={originalSession.coach?.first_name}
                            coachLastName={originalSession.coach?.last_name}
                            sessionTime={originalSession?.session_time}
                        />
                    )
                )}
            </Suspense>
        )
    }

    function renderSliderContent(): ReactElement {
        return (
            <Suspense fallback={<FullSpinner />}>
                {areAvailableSessionsLoading ? (
                    <FullSpinner className="mt-4" />
                ) : isErrorWithAvailableSessionsData ? (
                    <Message message={String(availableSessionsError?.response?.data)} />
                ) : !availableSessions?.length ? (
                    <NoSessionBlock />
                ) : (
                    <Slider
                        handleOpenSwapSessionModal={handleOpenSwapSessionModal}
                        handleSetSuggestedSession={handleSetSuggestedSession}
                        availableSessions={availableSessions}
                        originalSessionTime={originalSession?.session_time}
                    />
                )}
            </Suspense>
        )
    }

    return (
        <>
            <section className="swap-session__container">
                <div className="swap-session__wrapper">
                    {renderOriginalSessionBlock()}
                    {isMobile && <Heading />}
                    {renderSliderContent()}
                </div>
                <CareTeamLink
                    text={t("Need help?")}
                    variant="brand"
                    className="fs-14 mt-md-auto swap-session__care-link"
                />
            </section>

            {isSwapSessionModalOpen && (
                <ConfirmSwapModal
                    show={isSwapSessionModalOpen}
                    onClose={handleCloseSwapSessionModal}
                    sessionOriginal={originalSession}
                    sessionSuggested={suggestedSession}
                    handleSubmit={handleSubmitSwapSessionsClick}
                    mutationError={swapMutationError}
                    isMutationSuccessful={isSwapMutationSuccessful}
                    isSessionConfirmed={isSessionConfirmed}
                />
            )}
        </>
    )
}

export { SwapSessionContainer }
