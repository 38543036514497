import { ESpinnerSize, ESpinnerVariant } from "./spinner.types"

const sizesMap: { [K in ESpinnerSize]: string } = {
    [ESpinnerSize.Small]: "w-[20px] h-[20px]",
    [ESpinnerSize.Medium]: "w-[30px] h-[30px]",
    [ESpinnerSize.Large]: "w-[50px] h-[50px]"
}

const colorsMap: { [V in ESpinnerVariant]: string } = {
    [ESpinnerVariant.Accent]: "border-r-accent border-b-accent border-l-accent",
    [ESpinnerVariant.White]: "border-r-white border-b-white border-l-white"
}

const spinnerConfig: { sizesMap: { [K in ESpinnerSize]: string }; colorsMap: { [V in ESpinnerVariant]: string } } = {
    sizesMap,
    colorsMap
}

export { spinnerConfig }
