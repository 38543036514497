import { type FC, type ReactElement, type ReactNode, isValidElement } from "react"

import classNames from "classnames"

import { isString } from "$/utils/gates"

import { Typography } from "@/3514/components"
import { type IAuthContext, useAuthContext } from "@/context"
import Avatar from "@/shared/avatar/Avatar"

import { EParticipantCoachingMomentChatMessageAuthor as EMessageAuthor } from "../types"

import { participantCoachingMomentOwlComponents as owls } from "./participant-coaching-moment-chat-owls-components"

type TItemProps = {
    author: EMessageAuthor
    message: string | ReactNode
}

const classes = {
    container: (author: EMessageAuthor): string =>
        classNames(
            "flex w-full bg-transparent py-[7px] hover:!bg-black/5 transition-colors duration-200 relative",
            author === EMessageAuthor.User ? "pl-[55px] md:pl-[40px] pr-[20px]" : "pl-[20px] pr-[55px] md:pr-[40px]"
        ),
    messageRow: (author: EMessageAuthor): string =>
        classNames(
            "flex gap-x-[5px] items-end",
            author === EMessageAuthor.User ? "ml-auto" : "mr-auto flex-row-reverse"
        ),
    message: (author: EMessageAuthor, isContentAString: boolean): string =>
        classNames(
            "rounded-[20px] w-auto",
            isContentAString ? "py-[9px] px-[20px] break-words" : "p-[10px]",
            author === EMessageAuthor.User ? "bg-blue-500 max-w-[385px]" : "bg-red-500 max-w-[445px]"
        )
}

const avatarDimension: number = 30

const Message: FC<TItemProps> = ({ author, message }: TItemProps): ReactElement => {
    const { user }: IAuthContext = useAuthContext()

    return (
        <div className={classes.container(author)}>
            <div className={classes.messageRow(author)}>
                <div className={classes.message(author, !isValidElement(message))}>
                    {isString(message) ? <Typography text={message} /> : isValidElement(message) && message}
                </div>
                {author === EMessageAuthor.User ? (
                    <Avatar
                        width={avatarDimension}
                        height={avatarDimension}
                        url={user?.photo}
                        alt={`${user?.firstName} avatar`}
                        imgClassName={`min-w-[${avatarDimension}px]`}
                    />
                ) : (
                    <owls.DefaultLogo />
                )}
            </div>
        </div>
    )
}

Message.displayName = "ParticipantCoachingChatMessage"

export { Message as ParticipantCoachingMomentChatMessage }
