enum EButtonVariant {
    Solid = "solid",
    Outlined = "outlined",
    EmptyTransparent = "emptyTransparent"
}

enum EButtonSize {
    Medium = "medium",
    Small = "small",
    Container = "container"
}

export { EButtonVariant, EButtonSize }
