import { typographyConfig } from "./typography.config"

const { sizesMap, colorsMap } = typographyConfig

export { sizesMap as typographySizesClassMap }
export { colorsMap as typographyColorsClassMap }
export { Typography, type TTypographyProps } from "./typography.component"
export {
    ETypographySize,
    ETypographyTag,
    ETypographyColor,
    ETypographyFontWeight,
    ETypographyAlignment
} from "./typography.types"
