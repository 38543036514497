import { http } from "$/http"

import { EParticipantCoachingMomentChatApiMutationUrl as EMutationUrl } from "../../../config"

import type {
    TCreateCoachingMomentChatMessageMutationParams as TParams,
    TCreateCoachingMomentChatMessageMutationResponse as TResponse
} from "./create-coaching-moment-chat-message.hook"

async function fetcher({ message, conversationId }: TParams): Promise<TResponse> {
    return http.post(EMutationUrl.CreateMessage(Number(conversationId)), { message })
}

export { fetcher as createCoachingMomentChatMessageFetcher }
