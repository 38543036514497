import { yupResolver } from "@hookform/resolvers/yup"
import { type ObjectSchema } from "yup"
import * as yup from "yup"

import { EParticipantCoachingMomentSeedQuestionFormField } from "../types"

function _getSchema(): ObjectSchema {
    return yup
        .object()
        .shape({
            [EParticipantCoachingMomentSeedQuestionFormField.Answer]: yup
                .string()
                .required()
                .min(1)
                .matches(/^(?!\s*$)/)
                .max(5047),
            [EParticipantCoachingMomentSeedQuestionFormField.Question]: yup.string().notRequired(),
            [EParticipantCoachingMomentSeedQuestionFormField.Id]: yup.string().notRequired(),
            [EParticipantCoachingMomentSeedQuestionFormField.Index]: yup.number().notRequired()
        })
        .strict(true)
        .noUnknown(true)
}

const resolver: ReturnType<typeof yupResolver> = yupResolver(_getSchema())

export { resolver as participantCoachingMomentChatSeedQuestionFormResolver }
