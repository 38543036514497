import { Fragment, type ReactNode, createElement } from "react"

type TEmptyCallback = () => void

const emptyCallback: TEmptyCallback = (): void => {}

type TEmptyAsyncCallback = () => Promise<void>

const emptyAsyncCallback: TEmptyAsyncCallback = async (): Promise<void> => {}

type TEmptyRenderCallback = () => ReactNode

const emptyRenderCallback: TEmptyRenderCallback = (): ReactNode => createElement(Fragment)

export {
    type TEmptyCallback,
    emptyCallback,
    type TEmptyAsyncCallback,
    emptyAsyncCallback,
    type TEmptyRenderCallback,
    emptyRenderCallback
}
