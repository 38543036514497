import type { FC, ReactElement } from "react"

import {
    ESpecialChar,
    ETypographyColor,
    ETypographyFontWeight,
    ETypographySize,
    ETypographyTag,
    SpecialChars,
    Typography
} from "@/3514/components"

const classes = { box: "flex items-center justify-center relative", mark: "absolute select-none" }

const Icon: FC = (): ReactElement => (
    <div className={classes.box}>
        <svg width="35" height="36" viewBox="0 0 35 36" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M23.6018 0.854321C24.1323 0.849692 24.6428 1.05596 25.0211 1.42774L33.9385 10.1908C34.3168 10.5626 34.532 11.0694 34.5366 11.5999L34.6457 24.1018C34.6503 24.6322 34.4441 25.1428 34.0723 25.5211L25.3092 34.4385C24.9374 34.8168 24.4306 35.0319 23.9002 35.0366L11.3982 35.1457C10.8678 35.1503 10.3573 34.944 9.97892 34.5722L1.06156 25.8092C0.683228 25.4374 0.468083 24.9305 0.463455 24.4001L0.354351 11.8982C0.349723 11.3678 0.555989 10.8572 0.927775 10.4789L9.69085 1.56153C10.0626 1.1832 10.5695 0.968053 11.0999 0.963424L23.6018 0.854321Z"
                fill="#FD4D00"
            />
        </svg>
        <Typography
            className={classes.mark}
            weight={ETypographyFontWeight.Bold}
            text={SpecialChars[ESpecialChar.ExclamationMark]}
            color={ETypographyColor.White}
            size={ETypographySize.Small}
            tag={ETypographyTag.Span}
        />
    </div>
)

Icon.displayName = "ParticipantCoachingMomentChatErrorIcon"

export { Icon as ParticipantCoachingMomentChatErrorIcon }
