import "ckeditor5/ckeditor5.css"

import { type NamedExoticComponent, type ReactElement, Suspense, createElement, memo, useMemo } from "react"

import classNames from "classnames"

import { isEmpty, isObject, isString } from "$/utils/gates"

import { type TGetValueFromTranslatedObjectFunction, useCSSInsertion, useGetValueFromTranslatedObject } from "@/hooks"
import { useCkRendererStylesheetLink } from "@/shared/rich-text-renderer/hooks"
import { emptyCallback } from "@/shared/types/functions"

import { RichTextRendererFallbackSpinner as FallbackSpinner } from "./components"
import { richTextRenderers } from "./renderers"
import { ERichTextRendererMode, type TRichTextRendererProps } from "./rich-text-renderer-types"
import { type TRichRendererUtils, richTextRendererUtils } from "./rich-text-renderer-utils"

import "./rich-text-renderer-styles.scss"

const { propsChecker, isStringHTML, isStringMarkdown, isStringPlainText }: TRichRendererUtils = richTextRendererUtils

const classNamesMap: { [K in ERichTextRendererMode]: string } = {
    [ERichTextRendererMode.Edit]: "edit-mode",
    [ERichTextRendererMode.View]: "view-mode"
}

/**
 * Component for rendering custom created content on admin side.
 * @param content string content to render. Either HTML string or Markdown string or plain string
 * @param fallbackLoader custom loader
 * @param mode based on mode, toolbar and editing is active
 * @param onChange 'Edit' mode only! used for content state management
   @param isEditorForceSkipped boolean parameter to skip ckeditor
 * @param cssConfig css classNames config for insertion
 * @return ReactElement
 */
const Component: NamedExoticComponent<TRichTextRendererProps> = memo(
    ({
        content,
        fallbackLoader = undefined,
        mode = ERichTextRendererMode.Edit,
        onChange = emptyCallback,
        isEditorForceSkipped = false,
        cssConfig = {
            classNameIdentifier: String(),
            classNamesToOverride: String(),
            editorClassName: String()
        }
    }: TRichTextRendererProps): ReactElement => {
        const getValueFromTranslatedObject: TGetValueFromTranslatedObjectFunction = useGetValueFromTranslatedObject()

        const displayContent: string = useMemo(
            (): string =>
                isString(content) ? content : isObject(content) ? getValueFromTranslatedObject(content) : String(),
            [content, getValueFromTranslatedObject]
        )

        useCSSInsertion({
            cssString: cssConfig.classNamesToOverride,
            isEnabled:
                !isEmpty(cssConfig.classNameIdentifier) &&
                !isEmpty(cssConfig.classNamesToOverride) &&
                mode === ERichTextRendererMode.View
        })

        function renderContent(): ReactElement {
            let element: ReactElement = null

            if (mode === ERichTextRendererMode.Edit || (isStringHTML(displayContent) && !isEditorForceSkipped)) {
                element = createElement(richTextRenderers.HTMLRenderer, { content: displayContent, onChange, mode })
                return element
            }
            if (isStringHTML(displayContent) && isEditorForceSkipped) {
                element = <richTextRenderers.BasicRenderer content={displayContent} />
            }
            if (isStringMarkdown(displayContent)) {
                element = <richTextRenderers.MarkdownRenderer content={displayContent} />
            }
            if (isStringPlainText(displayContent)) {
                element = <richTextRenderers.BasicRenderer content={displayContent} />
            }

            return element
        }

        useCkRendererStylesheetLink()

        return (
            <Suspense fallback={fallbackLoader || <FallbackSpinner className="my-3" />}>
                <div
                    className={classNames("rich-text-renderer", {
                        [classNamesMap[mode]]: mode,
                        [cssConfig.classNameIdentifier]: cssConfig.classNameIdentifier,
                        [cssConfig.editorClassName]: cssConfig.editorClassName
                    })}
                >
                    {renderContent()}
                </div>
            </Suspense>
        )
    },
    propsChecker
)

Component.displayName = "RickTextRenderer"

export { Component as RichTextRenderer }
