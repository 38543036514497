import * as yup from "yup"
import type { ObjectSchema } from "yup"

import { ELikertFormField } from "./likert.context"

const getSchema: ({ isRequired }: { isRequired: boolean }) => ObjectSchema = ({
    isRequired
}: {
    isRequired: boolean
}): ObjectSchema =>
    yup.object({
        [ELikertFormField.QuestionList]: yup.array(
            yup.object({
                id: yup.number().required(),
                answer: yup.number().when("isNotApplicable", {
                    is: false,
                    then: isRequired ? yup.number().required() : yup.number().nullable(true),
                    otherwise: yup.number().nullable(true)
                }),
                isNotApplicable: yup.boolean().required(),
                freeResponsePrompt: yup.string().optional()
            })
        )
    })

export { getSchema as getLikertFormSchema }
