import { type FC, Fragment, type ReactElement } from "react"

import { isEmpty } from "$/utils/gates"

import { LoadableComponent } from "@/3514/components"
import { ParticipantHeaderEntity as Header } from "@/3514/entities"
import { EModalId } from "@/3514/store/slices"
import { type TUseModal, useModal } from "@/hooks"
import { AnalyticsModal, SessionAttendanceModal } from "@/modals"

import {
    ParticipantDashboardModuleEntity as Module // ,ParticipantDashboardResourcesEntity as Resources
} from "."

const Container: FC = (): ReactElement => {
    const { getModal }: TUseModal = useModal()

    return (
        <Fragment>
            <Header componentRendererProps={{ withTitle: true }} withNextSessionBlock />

            <Module />

            {/* <Resources /> */}

            <LoadableComponent
                isReady={!isEmpty(getModal(EModalId.AnalyticsDashboard))}
                component={<AnalyticsModal />}
            />

            <LoadableComponent
                isReady={!isEmpty(getModal(EModalId.SessionAttendance))}
                component={<SessionAttendanceModal />}
            />
        </Fragment>
    )
}

Container.displayName = "ParticipantDashboardContainer"

export { Container as ParticipantDashboardContainer }
