import { useCallback } from "react"

import { type MutationOptions, type UseMutationResult, useMutation } from "@tanstack/react-query"
import type { AxiosError, AxiosResponse } from "axios"

import { EParticipantCoachingMomentChatApiMutationKey as EMutationKey } from "../../../config"

import { createCoachingMomentChatMessageFetcher as fetcher } from "./create-coaching-moment-chat-message.fetcher"

type TParams = {
    conversationId: string
    message: string
}

type TResponse = AxiosResponse<{ message_id: number }>

type TMutation = UseMutationResult<TResponse>

function useHook(options?: MutationOptions): ReturnType<() => TMutation> {
    return useMutation<TResponse, AxiosError, TParams>(
        [EMutationKey.CreateCoachingMomentChatMessage],
        useCallback(fetcher, []),
        // @ts-expect-error react-query types mess here, no error
        { ...options }
    )
}

export {
    useHook as useCreateCoachingMomentChatMessageMutation,
    type TMutation as TCreateCoachingMomentChatMessageMutation,
    type TParams as TCreateCoachingMomentChatMessageMutationParams,
    type TResponse as TCreateCoachingMomentChatMessageMutationResponse
}
