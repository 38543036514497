import {
    type ForwardRefExoticComponent,
    type ForwardedRef,
    Fragment,
    type ReactElement,
    type ReactNode,
    forwardRef
} from "react"

import classNames from "classnames"

import { ETypographyColor, ETypographySize, ETypographyTag, Typography } from "@/3514/components"
import { type IUseTranslation, useTranslation } from "@/hooks"

type TProps = { isCompleted?: boolean; wrapperClassName?: string }

const checkIcon: ReactNode = (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M5.0625 9.1125L7.64062 11.8125L13.5 6.1875"
            stroke="#737373"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
)

const Component: ForwardRefExoticComponent<TProps> = forwardRef(
    ({ isCompleted, wrapperClassName = String() }: TProps, ref: ForwardedRef<HTMLDivElement>): ReactElement => {
        const { t }: IUseTranslation = useTranslation()

        return (
            <div
                ref={ref}
                className={classNames("absolute top-auto left-auto right-0 bottom-0 flex items-center", {
                    [wrapperClassName]: wrapperClassName
                })}
            >
                <div className="pt-[4px] pr-[12px] pb-[6px] pl-[5px] flex items-center gap-x-[3px] select-none">
                    {isCompleted ? (
                        <Fragment>
                            {checkIcon}

                            <Typography
                                text={t("Completed")}
                                tag={ETypographyTag.Span}
                                color={ETypographyColor.DarkGray}
                                size={ETypographySize.Tiny}
                            />
                        </Fragment>
                    ) : (
                        <Fragment>
                            <div className="flex items-center justify-center w-[18px] h-[18px]">
                                <i className="border-gray-23 border-dashed border-[1px] rounded-full h-[10px] w-[10px]" />
                            </div>
                            <Typography
                                text={t("Incomplete")}
                                isItalic
                                tag={ETypographyTag.Span}
                                color={ETypographyColor.DarkGray}
                                size={ETypographySize.Tiny}
                            />
                        </Fragment>
                    )}
                </div>
            </div>
        )
    }
)

Component.displayName = "ChapterComponentCompletionMark"

export { Component as ChapterComponentCompletionMark }
