import { type FC, type LazyExoticComponent, type NamedExoticComponent, type ReactElement, lazy } from "react"

import { isEmpty } from "$/utils/gates"

import {
    ETypographyAlignment,
    ETypographyFontWeight,
    ETypographySize,
    ETypographyTag,
    LoadableComponent,
    Typography
} from "@/3514/components"
import { type IUseTranslation, useTranslation } from "@/hooks"
import { type TResourcesQuery, useResourcesQuery } from "@/pages/participant-dashboard/api"

import { type TParticipantDashboardResourcesListProps as TListProps } from "./components"

const List: LazyExoticComponent<NamedExoticComponent<TListProps>> = lazy(
    (): Promise<{ default: NamedExoticComponent<TListProps> }> =>
        import("./components/participant-dashboard-resources-list.component").then(
            ({ ParticipantDashboardResourcesList: List }): { default: NamedExoticComponent<TListProps> } => ({
                default: List
            })
        )
)

const classes: { section: string; container: string; title: string } = {
    section: "max-w-[800px] w-full mx-auto pb-[40px] md:pb-[100px] max-[767px]:px-[20px]",
    container: "max-w-[560px] w-full mx-auto",
    title: "mb-[10px] md:mb-[20px]"
}

/**
 * ParticipantDashboard' resources section. Design: {@link https://www.figma.com/design/J6qF3Pa6zeK3udtVWLskjd/Participant?node-id=13088-96027&m=dev}
 * @return ReactElement
 */
const Component: FC = (): ReactElement => {
    const { data: resourcesData, isFetching: isResourcesDataFetching }: TResourcesQuery = useResourcesQuery({
        enabled: false
    })

    const { t }: IUseTranslation = useTranslation()

    return (
        <section className={classes.section}>
            <Typography
                text={t("participantSide.dashboard.resourcesBlock.title")}
                tag={ETypographyTag.Heading2}
                size={ETypographySize.Heading2}
                weight={ETypographyFontWeight.Bold}
                alignment={ETypographyAlignment.Center}
                className={classes.title}
            />
            <div className={classes.container}>
                <LoadableComponent
                    isLoading={isResourcesDataFetching}
                    isReady={!isEmpty(resourcesData)}
                    component={<List resourcesList={resourcesData} />}
                />
            </div>
        </section>
    )
}

Component.displayName = "ParticipantDashboardResourcesEntity"

export { Component as ParticipantDashboardResourcesEntity }
