import { type FC, type ReactElement, useEffect } from "react"

import useMediaQuery from "$/hooks/use-media-query"

import { MAX_WIDTH_MOBILE_MEDIA } from "@/constants"
import { ParticipantLayout } from "@/layouts"
import BackButton from "@/shared/back-btn/BackButton"
import { LeftSidebar } from "@/shared/participant-left-sidebar"
import { SIDEBAR_MENU_TOGGLER_ID } from "@/shared/sidebar/Sidebar"

import { SwapSessionContainer as Container, SwapSessionHeading as Heading } from "."

import "./SwapSession.styles.scss"

const SwapSessionPage: FC = (): ReactElement => {
    const isMobile: boolean = useMediaQuery(MAX_WIDTH_MOBILE_MEDIA)

    useEffect(() => () => document?.getElementById(SIDEBAR_MENU_TOGGLER_ID)?.click(), [])

    return (
        <ParticipantLayout
            className="h-100"
            leftSidebar={<LeftSidebar integrated closedMenu />}
            {...(!isMobile && {
                title: (
                    <div className="swap-session__header">
                        <BackButton className="color-brand back-btn-float" />
                        <Heading />
                    </div>
                )
            })}
        >
            <Container />
        </ParticipantLayout>
    )
}

export { SwapSessionPage }
