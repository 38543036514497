import {
    type BaseSyntheticEvent,
    type ChangeEvent,
    type Dispatch,
    type FC,
    type KeyboardEvent,
    type MouseEvent,
    type MutableRefObject,
    type ReactElement,
    type SetStateAction,
    useRef,
    useState
} from "react"

import classNames from "classnames"
import { type UseFormReturn, useForm } from "react-hook-form"
import { CSSTransition } from "react-transition-group"

import { isEmpty } from "$/utils/gates"

import {
    Button,
    ChapterComponentCompletionMark as CompletionMark,
    EButtonSize,
    ETypographyAlignment,
    ETypographyColor,
    ETypographyFontWeight,
    ETypographySize,
    TextArea,
    Typography
} from "@/3514/components"
import { getAppearanceAnimationCssString } from "@/3514/utils"
import { type IUseTranslation, useCSSInsertion, useTranslation } from "@/hooks"
import { LocalStorageServiceKeys, StorageService } from "@/services"
import type { TEmptyCallback } from "@/shared/types/functions"

import { useParticipantCoachingMomentChatContext as useChatContext } from "../context"
import {
    EParticipantCoachingMomentSeedQuestionFormField as EFormField,
    type TParticipantCoachingMomentSeedQuestionFormField as TFormField
} from "../types"
import { participantCoachingMomentChatSeedQuestionFormResolver as formResolver } from "../validation"

const fieldClasses: {
    wrapper(isButtonVisible: boolean): string
    title(withDivider: boolean): string
    descWrapper(isFieldSubmitted: boolean): string
    textarea(isFieldActive: boolean, isFieldSubmitted: boolean): string
    button(isButtonVisible: boolean): string
} = {
    wrapper: (isButtonVisible: boolean): string =>
        classNames(
            [
                "px-[20px]",
                "pt-[20px]",
                "flex",
                "flex-auto",
                "h-full",
                "min-h-full",
                "flex-col",
                "gap-y-[10px]",
                "bg-blue-500",
                "rounded-[10px]",
                "relative",
                "max-h-fit",
                "group"
            ],
            isButtonVisible ? "pb-[10px]" : "pb-[30px]"
        ),
    title: (withDivider: boolean): string =>
        classNames(
            "relative",
            withDivider && [
                "after:content-['']",
                "after:absolute",
                "after:block",
                "after:bottom-[-13px]",
                "after:left-0",
                "after:right-0",
                "after:h-[1px]",
                "after:bg-blue-300"
            ]
        ),
    descWrapper: (isFieldSubmitted: boolean): string =>
        classNames(
            "relative flex-auto flex flex-col justify-start items-center h-full min-h-[110px]",
            isFieldSubmitted && [
                "after:content-['']",
                "after:absolute",
                "after:bottom-0",
                "after:left-0",
                "after:right-0",
                "after:h-[3em]",
                "after:bg-gradient-to-t",
                "after:from-blue-500",
                "after:to-transparent",
                "after:pointer-events-none"
            ],
            isFieldSubmitted ? "gap-y-[3px]" : "gap-y-[10px]"
        ),
    button: (isButtonVisible: boolean): string => classNames(!isButtonVisible && "hidden"),
    textarea: (isFieldActive: boolean, isFieldSubmitted: boolean): string =>
        classNames(
            "h-full break-keep no-scrollbar !min-h-[99px] !cursor-auto max-h-[110px]",
            !isFieldActive && !isFieldSubmitted && "group-hover:!bg-blue-600 group-hover:!border-blue-400",
            isFieldSubmitted && "group-hover:!bg-transparent group-hover:!border-transparent",
            !isFieldActive && "p-[0px] bg-transparent !border-transparent",
            !isFieldActive && isFieldSubmitted && ["relative", "overflow-y-scroll", "w-full"]
        )
}

type TFieldProps = {
    index: number
    completionMarkAnimationClass: string
}

const storageService: StorageService = new StorageService()

const Field: FC<TFieldProps> = ({ index, completionMarkAnimationClass }: TFieldProps): ReactElement => {
    const { t }: IUseTranslation = useTranslation()

    const {
        form: { questions, wasSubmitted: wasFormSubmitted, handleQuestionSubmit }
    }: ReturnType<typeof useChatContext> = useChatContext()

    const question: TFormField = questions[index]

    const {
        setValue: setFieldValue,
        watch: watchChange,
        formState: { isValid: isFormValid, isSubmitted: _isFormSubmitted },
        handleSubmit
    }: UseFormReturn<TFormField> = useForm({
        reValidateMode: "onChange",
        resolver: formResolver,
        defaultValues: {
            ...question,
            answer: isEmpty(question.answer)
                ? storageService.getItem(LocalStorageServiceKeys.CoachingMomentSeedQuestion(question.id)) || String()
                : question.answer
        }
    })

    const isFormSubmitted: boolean = _isFormSubmitted || wasFormSubmitted

    const field: TFormField = watchChange()

    const [isCompletionMarkVisible, setIsCompletionMarkVisible]: [boolean, Dispatch<SetStateAction<boolean>>] =
        useState<boolean>(true)

    const [isButtonVisible, setIsButtonVisible]: [boolean, Dispatch<SetStateAction<boolean>>] = useState<boolean>(false)

    const completionMarkRef: MutableRefObject<HTMLDivElement> = useRef<HTMLDivElement>(null)

    const [isTextAreaActive, setIsTextAreaActive]: [boolean, Dispatch<SetStateAction<boolean>>] =
        useState<boolean>(false)

    const textAreaRef: MutableRefObject<HTMLTextAreaElement> = useRef<HTMLTextAreaElement>(null)

    const handleTextAreaChange: (e: ChangeEvent<HTMLTextAreaElement>) => Promise<void> = async (
        e: ChangeEvent<HTMLTextAreaElement>
    ): Promise<void> => (
        setFieldValue(EFormField.Answer, e.target.value, {
            shouldValidate: true,
            shouldTouch: true
        }),
        storageService.setItem(LocalStorageServiceKeys.CoachingMomentSeedQuestion(question.id), e.target.value)
    )

    const handleFormSubmit: TEmptyCallback = handleSubmit(
        async (): Promise<void> => (
            await handleQuestionSubmit(field).then(
                (): void => (setIsCompletionMarkVisible(true), setIsButtonVisible(false), setIsTextAreaActive(false))
            ),
            void 0
        )
    )

    const handleTextAreaFocus: TEmptyCallback = (): void =>
        !isFormSubmitted &&
        (setIsCompletionMarkVisible(false),
        setIsButtonVisible(true),
        setIsTextAreaActive(true),
        textAreaRef?.current?.focus(),
        void 0)

    const handleTextAreaBlur: TEmptyCallback = (): void =>
        isEmpty(field.answer) && (setIsCompletionMarkVisible(true), setIsTextAreaActive(false), void 0)

    const handleTextAreaKeyDown: (e: KeyboardEvent) => void = async (event: KeyboardEvent): Promise<void> => {
        if (event.key === "Enter" && isFormValid) {
            event.preventDefault()
            await handleFormSubmit()
        }
    }

    const handleWrapperMouseLeave: TEmptyCallback = (): void =>
        isEmpty(field.answer) &&
        (setIsCompletionMarkVisible(true),
        setIsTextAreaActive(false),
        setIsButtonVisible(false),
        textAreaRef.current.blur())

    const handleWrapperAction: (e?: BaseSyntheticEvent) => void = (e: KeyboardEvent & MouseEvent): void => {
        if (e.type === "click") handleTextAreaFocus()
        else if (e.key === "Enter") handleTextAreaFocus()
    }

    return (
        <form tabIndex={-1} onSubmit={handleFormSubmit}>
            <div
                role="tabpanel"
                className={fieldClasses.wrapper(isButtonVisible)}
                onMouseLeave={handleWrapperMouseLeave}
                onClick={handleWrapperAction}
                onKeyDown={handleWrapperAction}
            >
                <Typography
                    text={field.question}
                    color={ETypographyColor.Dark}
                    size={ETypographySize.Small}
                    weight={ETypographyFontWeight.Bold}
                    alignment={ETypographyAlignment.Center}
                    className={fieldClasses.title(isFormSubmitted)}
                />
                <div className={fieldClasses.descWrapper(isFormSubmitted)}>
                    <TextArea
                        withResizeDisabled
                        ref={textAreaRef}
                        value={field.answer}
                        isDisabled={isFormSubmitted}
                        textColor={ETypographyColor.Dark}
                        textSize={ETypographySize.Small}
                        className={fieldClasses.textarea(isTextAreaActive, isFormSubmitted)}
                        onChange={handleTextAreaChange}
                        onKeyDown={handleTextAreaKeyDown}
                        onFocus={handleTextAreaFocus}
                        onBlur={handleTextAreaBlur}
                    />
                    <Button
                        size={EButtonSize.Small}
                        className={fieldClasses.button(isButtonVisible)}
                        isDisabled={!isFormValid}
                    >
                        {t("Done")}
                    </Button>
                </div>

                <CSSTransition
                    timeout={150}
                    nodeRef={completionMarkRef}
                    classNames={completionMarkAnimationClass}
                    in={isCompletionMarkVisible}
                    mountOnEnter
                    unmountOnExit
                >
                    <CompletionMark {...{ ref: completionMarkRef }} isCompleted={isFormSubmitted} />
                </CSSTransition>
            </div>
        </form>
    )
}

const listClasses: { wrapper(): string } = {
    wrapper: (): string =>
        "[display:grid] grid-cols-1 md:grid-cols-2 gap-y-[10px] md:gap-y-[0px] gap-x-[0px] md:gap-x-[10px]"
}

const List: FC = (): ReactElement => {
    const fieldMarkAnimationIdentifier: string = "cmcf-cm"

    useCSSInsertion({
        cssString: getAppearanceAnimationCssString({
            identifier: fieldMarkAnimationIdentifier,
            durationInMs: 150
        })
    })

    const {
        form: { questions }
    }: ReturnType<typeof useChatContext> = useChatContext()

    return (
        <div className={listClasses.wrapper()}>
            {questions.map(
                ({ id }: TFormField, idx: number): ReactElement => (
                    <Field key={id} index={idx} completionMarkAnimationClass={fieldMarkAnimationIdentifier} />
                )
            ) ?? null}
        </div>
    )
}

List.displayName = "ParticipantCoachingMomentChatFields"

export { List as ParticipantCoachingMomentChatFields }
