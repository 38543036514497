import { type FC, type ReactElement, type ReactNode, useId } from "react"

import { ETypographyColor, ETypographyFontWeight, ETypographySize, Typography } from "@/3514/components"
import { getAppearanceAnimationCssString } from "@/3514/utils"
import { useCSSInsertion } from "@/hooks"

import { LikertQuestionRow as Row } from "./components"
import { type TLikertQuestion } from "./likert.types"

type TProps = {
    title: string
    questionList: TLikertQuestion[]
    renderCompletionStatus?(): ReactNode
    renderSummary?(): ReactNode
}

/**
 * Likert component. Design reference: {@link https://www.figma.com/design/J6qF3Pa6zeK3udtVWLskjd/Participant?node-id=13157-150819&m=dev}
 * @param title string title
 * @param questionList list of questions
 * @param renderCompletionStatus (Chapter page) completion progress on the bottom
 * @param renderSummary (Chapter page) summary component below the Likert
 * @return ReactElement
 */
const Component: FC<TProps> = ({
    title,
    questionList,
    renderCompletionStatus,
    renderSummary
}: TProps): ReactElement => {
    const likertId: string = useId()

    const rowTextareaAnimationIdentifier: string = "lrtai"

    useCSSInsertion({ cssString: getAppearanceAnimationCssString({ identifier: rowTextareaAnimationIdentifier }) })

    return (
        <div className="flex flex-col gap-y-[10px] items-center">
            <section className="bg-blue-500 rounded-[10px] pt-[25px] px-[10px] md:px-[30px] pb-[40px] relative flex flex-col items-center">
                <div className="flex flex-col items-center gap-y-[25px]">
                    <Typography
                        text={title}
                        weight={ETypographyFontWeight.Bold}
                        size={ETypographySize.Small}
                        color={ETypographyColor.Dark}
                    />

                    {questionList.map(
                        ({ id, order, ...rest }: TLikertQuestion): ReactElement => (
                            <Row
                                key={`${likertId}-${id}-${order}`}
                                {...rest}
                                id={id}
                                textareaAnimationClass={rowTextareaAnimationIdentifier}
                            />
                        )
                    )}

                    {renderCompletionStatus?.()}
                </div>
            </section>

            {renderSummary?.()}
        </div>
    )
}

Component.displayName = "Likert"

export { Component as Likert }
