import { useMemo, useState } from "react"

import { datadogLogs } from "@datadog/browser-logs"
import { useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { useNavigate, useParams } from "react-router-dom"
import { v4 as uuid } from "uuid"

import Heading from "$/components/Heading/Heading"
import { http } from "$/http"
import { i18n } from "$/i18n"
import { isNullOrUndefined } from "$/utils/gates"

import Urls from "@/api/urls"
import useCoachingSessions from "@/api/use-coaching-sessions"
import { CoachRatingAskedFrom, INDEX_URL, ProductType, ReflectionComponentType } from "@/constants"
import { useAuthContext } from "@/context"
import CoachRating from "@/elements/coach-rating/CoachRating"
import { ParticipantLayout } from "@/layouts"
import Coach from "@/models/coach"
import { CohortModalities } from "@/models/types"
import Button from "@/shared/button/Button"
import LikertComponent from "@/shared/likert/LikertComponent"
import Modal from "@/shared/modal"
import { LeftSidebar } from "@/shared/participant-left-sidebar"
import { formatMonthDayYear } from "@/utils/date"

import "./styles.scss"

const LIKERT_ITEM = {
    uuid: uuid(),
    question: {
        en: i18n.t("I am making progress towards my coaching goal")
    },
    component_type: ReflectionComponentType.LIKERT_GROUP,
    options: [
        {
            response: {
                en: i18n.t("Strongly disagree")
            },
            uuid: uuid()
        },
        {
            response: {
                en: i18n.t("Disagree")
            },
            uuid: uuid()
        },
        {
            response: {
                en: i18n.t("Neutral")
            },
            uuid: uuid()
        },
        {
            response: {
                en: i18n.t("Agree")
            },
            uuid: uuid()
        },
        {
            response: {
                en: i18n.t("Strongly Agree")
            },
            uuid: uuid()
        }
    ]
}

export const CoachRatingPage = () => {
    const { t } = useTranslation()
    const params = useParams()
    const navigate = useNavigate()
    const { data, isLoading } = useCoachingSessions()
    const { user } = useAuthContext()
    const [showFeedbackPopup, setShowFeedbackPopup] = useState(false)
    const [feedbackRating, setFeedbackRating] = useState({ coachRatingId: null, freeResponse: null })
    const [noAnswer, setNoAnswer] = useState(false)
    const [isSubmit, setIsSubmit] = useState(false)

    const session = useMemo(() => {
        if (data && !isLoading) {
            const session = [...data?.passed_sessions, ...data?.upcoming_sessions]?.find(
                session => session.session_id === +params.sessionId
            )
            return session
        }
        return null
    }, [data, isLoading])

    const coach = useMemo(() => {
        if (session) {
            return !isNullOrUndefined(session?.coach) ? new Coach(session?.coach) : null
        }
        return null
    }, [data, isLoading])

    const onClick = () => {
        navigate(INDEX_URL)
    }

    const isIndividualLeadershipSession = useMemo(() => {
        return (
            session?.cohort_modality === CohortModalities.Individual &&
            session?.product_type_internal_name === ProductType.LEADERSHIP
        )
    }, [session])

    const {
        register,
        control,
        setError,
        getValues,
        formState: { errors }
    } = useForm()

    const onClickSubmit = () => {
        const values = getValues()
        const likertAnswer = LIKERT_ITEM.options.find(option => option.uuid === values.coachingFeedbackLikert)

        if (!isSubmit) {
            setNoAnswer(true)
            return
        }

        if (!values.coachingFeedbackLikert && isIndividualLeadershipSession) {
            setError("coachingFeedbackLikert", { message: t("Answer is required") })
            return
        }

        if (isIndividualLeadershipSession) {
            sendDataDogLog(values.coachingFeedbackLikert, likertAnswer.response.en)
        }

        if (!isNullOrUndefined(feedbackRating.coachRatingId)) {
            sendRatingResponseWithLikertAnswer(likertAnswer.response.en)
        }

        setIsSubmit(false)
        setNoAnswer(false)
        setShowFeedbackPopup(true)
    }

    const onSubmitRating = () => {
        setIsSubmit(true)
    }

    const saveRatingAndFreeResponse = (coachRatingId: number, freeResponse: string) => {
        setFeedbackRating({ coachRatingId, freeResponse })
    }

    const sendRatingResponseWithLikertAnswer = async (answer: string) => {
        try {
            await http.patch(Urls.submitRating(feedbackRating.coachRatingId), {
                free_response: `${feedbackRating.freeResponse ?? ""} / ${answer}`
            })
        } catch (error) {
            console.log(error)
        }
    }

    const sendDataDogLog = (optionUuid: string, answer: string) => {
        datadogLogs.logger.info("COACH RATING FEEDBACK", {
            userId: user.id,
            name: "LIKERT_QUESTION",
            question: LIKERT_ITEM.question.en,
            answer,
            optionUuid
        })
    }

    return (
        <ParticipantLayout
            leftSidebar={<LeftSidebar />}
            title={
                <Heading textAlign="center" className="mb-1">
                    {t("Rate Your Recent Session")}
                </Heading>
            }
        >
            <div className="container">
                <div className="questions-container">
                    <p className="color-gray text-center fs-14 mb-50">
                        {session?.session_type?.name}: {formatMonthDayYear(session?.session_time ?? "")}
                    </p>
                    <section className="reflection-container">
                        <CoachRating
                            askedFrom={CoachRatingAskedFrom.POST_MODULE_REFLECTION_POPUP}
                            coachSessionId={+params?.sessionId}
                            coachData={coach}
                            errorMessage={noAnswer && !isSubmit ? t("Answer is required") : ""}
                            sendFreeResponse={!isIndividualLeadershipSession}
                            saveCoachRating={isIndividualLeadershipSession ? saveRatingAndFreeResponse : undefined}
                            onSubmitCoachRating={onSubmitRating}
                        />

                        {isIndividualLeadershipSession && (
                            <LikertComponent
                                name="coachingFeedbackLikert"
                                questionNumber={0}
                                className="likert-session-page text-center"
                                register={register}
                                item={LIKERT_ITEM}
                                color="brand"
                                control={control}
                                errors={errors}
                            />
                        )}

                        <div className="text-center">
                            <Button onClick={onClickSubmit}>{t("Submit")}</Button>
                        </div>
                    </section>
                </div>
            </div>
            <Modal
                show={showFeedbackPopup}
                onClose={() => setShowFeedbackPopup(false)}
                enableOutSideClick={false}
                modalBodyClass="coach-rating-feeback-modal"
                disableBodyScroll
            >
                <Heading textAlign="center" tag="h2" fontSize={24} className="mb-30" fontWeight={500}>
                    {t("Thank you for your answer!")}
                </Heading>
                <div className="text-center">
                    <Button onClick={onClick}>{t("OK")}</Button>
                </div>
            </Modal>
        </ParticipantLayout>
    )
}
