import { type FC, type ReactElement, type ReactNode } from "react"

import classNames from "classnames"

import { isEmpty } from "$/utils/gates"

import { useParticipantCoachingMomentChatContext as useChatContext } from "@/3514/entities/participant-coaching-moment-chat/context"
import { useCSSInsertion } from "@/hooks"
import { type TEmptyRenderCallback } from "@/shared/types/functions"

import {
    EParticipantCoachingMomentChatStatus as EChatStatus,
    EParticipantCoachingMomentChatMessageAuthor as EMessageAuthor
} from "../types"
import { participantCoachingMomentChatUiUtils as utils } from "../utils"

import { ParticipantCoachingMomentChatMessage as Message } from "./participant-coaching-moment-chat-message.component"

const { getChatTypingIndicatorClassNamesStringForInsertion: getCssString } = utils

const renderMessage: TEmptyRenderCallback = (): ReactNode => (
    <div className="flex items-end flex-none">
        <div className="flex items-center justify-center h-[20px] gap-x-[6px] px-[6px] bg-transparent rounded-lg">
            {Array.from(
                { length: 3 },
                (_i: number, index: number): ReactElement => (
                    <i
                        key={`pcmcti-${index}`}
                        className={classNames(
                            "w-[7px] h-[7px] bg-gray-300 rounded-full animate-bounce-dot",
                            index === 1 && "animate-delay-150",
                            index === 2 && "animate-delay-300"
                        )}
                    />
                )
            )}
        </div>
    </div>
)

const Indicator: FC = (): ReactElement => {
    useCSSInsertion({
        cssString: getCssString()
    })

    const {
        chat: { status, messages }
    }: ReturnType<typeof useChatContext> = useChatContext()

    const isVisible: boolean =
        status === EChatStatus.InProgress &&
        (isEmpty(messages) || messages[messages.length - 1]?.author === EMessageAuthor.User)

    return (
        <div className={classNames(isVisible || "hidden")}>
            <Message author={EMessageAuthor.AI} message={renderMessage()} />
        </div>
    )
}

Indicator.displayName = "ParticipantCoachingMomentChatTypingIndicator"

export { Indicator as ParticipantCoachingMomentChatTypingIndicator }
