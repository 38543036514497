import { yupResolver } from "@hookform/resolvers/yup"
import { type ObjectSchema } from "yup"
import * as yup from "yup"

import { EParticipantCoachingMomentChatInputFormField } from "../types"

function _getSchema(): ObjectSchema {
    return yup.object().shape({
        [EParticipantCoachingMomentChatInputFormField.ChatInput]: yup
            .string()
            .min(1)
            .max(5047)
            .matches(/^(?!\s*$)/)
            .required()
    })
}

const resolver: ReturnType<typeof yupResolver> = yupResolver(_getSchema())

export { resolver as participantCoachingMomentChatInputFormResolver }
