import { type FC, type MutableRefObject, type ReactElement, useEffect, useRef } from "react"

import { type TEmptyCallback, type TEmptyRenderCallback, emptyCallback } from "@/shared/types/functions"

import { useParticipantCoachingMomentChatContext as useChatContext } from "../context"

type TProps = {
    renderMessages: TEmptyRenderCallback
    renderTypingIndicator: TEmptyRenderCallback
    renderErrorBox: TEmptyRenderCallback
    renderSummary: TEmptyRenderCallback
}

const classes: { container(): string; feed(): string } = {
    container: (): string => "overflow-y-auto no-scrollbar",
    feed: (): string => "w-full justify-end overflow-y-scroll no-scrollbar h-max mt-[40px]"
}

const Feed: FC<TProps> = ({
    renderMessages,
    renderTypingIndicator,
    renderErrorBox,
    renderSummary
}: TProps): ReactElement => {
    const {
        chat: { messages, status }
    }: ReturnType<typeof useChatContext> = useChatContext()

    const feedRef: MutableRefObject<HTMLDivElement> = useRef<HTMLDivElement>(null)

    useEffect((): TEmptyCallback => {
        if (!messages.length || !feedRef?.current) return emptyCallback

        const timeoutId: ReturnType<typeof setTimeout> = setTimeout((): void => {
            const element: HTMLDivElement = feedRef.current
            element.scrollTop = element.scrollHeight
        }, 0)

        return (): void => clearTimeout(timeoutId)
    }, [messages.length, status])

    return (
        <div ref={feedRef} className={classes.container()}>
            <div className={classes.feed()}>
                {renderMessages()}

                {renderTypingIndicator()}

                {renderErrorBox()}

                {renderSummary()}
            </div>
        </div>
    )
}

Feed.displayName = "ParticipantCoachingMomentChatFeed"

export { Feed as ParticipantCoachingMomentChatFeed }
