import { type MutableRefObject, useInsertionEffect, useRef } from "react"

import { isEmpty } from "$/utils/gates"

import { type TEmptyCallback, emptyCallback } from "@/shared/types/functions"

type TParams = { cssString: string; isEnabled?: boolean }

function useCSSInsertion({ cssString, isEnabled = true }: TParams): void {
    const styleRef: MutableRefObject<HTMLStyleElement> = useRef<HTMLStyleElement>(null)

    useInsertionEffect((): TEmptyCallback => {
        if (isEmpty(cssString) || !isEnabled) return emptyCallback

        if (!styleRef.current) {
            try {
                const styleElement: HTMLStyleElement = document.createElement("style")
                styleElement.textContent = cssString
                document.head.appendChild(styleElement)
                styleRef.current = styleElement
            } catch (e: unknown) {
                console.log(e)
            }
        }

        return (): void => {
            if (styleRef.current) {
                document.head.removeChild(styleRef.current)
                styleRef.current = null
            }
        }
    }, [cssString])
}

export { useCSSInsertion }
