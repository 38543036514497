import { isEmpty } from "$/utils/gates"

import type { TCreateCoachingMomentConversationMutationParams } from "../api/mutations"
import {
    EParticipantCoachingMomentChatConversationStatus,
    EParticipantCoachingMomentChatMessageAuthor,
    type IParticipantCoachingMomentChatConversationDto,
    type IParticipantCoachingMomentChatConversationModel,
    type IParticipantCoachingMomentChatMessageDto,
    type IParticipantCoachingMomentChatMessageModel,
    type TParticipantCoachingMomentChatConversationStatus,
    type TParticipantCoachingMomentChatMessageAuthor,
    type TParticipantCoachingMomentSeedQuestionFormField
} from "../types"

function mapContextFieldsValuesToCreateConversationMutationParams(
    questions: TParticipantCoachingMomentSeedQuestionFormField[],
    chapterId: number,
    chapterComponentId: number
): TCreateCoachingMomentConversationMutationParams {
    return {
        chapter_progress: chapterId,
        component: chapterComponentId,
        seed_answers: questions.map(
            ({ answer, id: question_uuid }: TParticipantCoachingMomentSeedQuestionFormField) => ({
                answer,
                question_uuid
            })
        )
    }
}

function _getChatMessageAuthorEnumFromType(
    author: TParticipantCoachingMomentChatMessageAuthor
): EParticipantCoachingMomentChatMessageAuthor {
    switch (author) {
        case "ai":
            return EParticipantCoachingMomentChatMessageAuthor.AI
        case "participant":
            return EParticipantCoachingMomentChatMessageAuthor.User
        default:
            return null
    }
}

function getConversationStatusEnumFromType(
    status: TParticipantCoachingMomentChatConversationStatus
): EParticipantCoachingMomentChatConversationStatus {
    switch (status) {
        case "completed":
            return EParticipantCoachingMomentChatConversationStatus.Completed
        case "in_progress":
            return EParticipantCoachingMomentChatConversationStatus.InProgress
        default:
            return null
    }
}

function getChatMessageModelFromDto(
    dto: IParticipantCoachingMomentChatMessageDto
): IParticipantCoachingMomentChatMessageModel {
    return isEmpty(dto)
        ? null
        : {
              id: dto.id,
              conversationId: dto.conversation_id,
              conversation: getChatConversationModelFromDto(dto.conversation),
              message: dto.message,
              author: _getChatMessageAuthorEnumFromType(dto.author_role)
          }
}

function getChatConversationModelFromDto(
    dto: Partial<IParticipantCoachingMomentChatConversationDto>
): IParticipantCoachingMomentChatConversationModel {
    return isEmpty(dto)
        ? { completedStamp: null, id: null, summary: null, status: null, messages: [] }
        : {
              completedStamp: dto.completed_stamp,
              id: dto.id,
              summary: dto.summary,
              status: getConversationStatusEnumFromType(dto.status),
              messages: isEmpty(dto.messages)
                  ? []
                  : dto.messages.map(
                        (msg: IParticipantCoachingMomentChatMessageDto): IParticipantCoachingMomentChatMessageModel =>
                            getChatMessageModelFromDto(msg)
                    )
          }
}

const utils = {
    mapContextFieldsValuesToCreateConversationMutationParams,
    getChatMessageModelFromDto,
    getConversationStatusEnumFromType,
    getChatConversationModelFromDto
}

export { utils as participantCoachingMomentChatApiUtils }
