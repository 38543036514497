import { type FC, type ForwardedRef, type NamedExoticComponent, type ReactElement, forwardRef, memo } from "react"

import classNames from "classnames"

import { isEqual } from "$/utils/gates"

import { ParticipantCoachingMomentChatProvider as Provider } from "./context"
import type {
    TParticipantCoachingMomentChatConfig as TChatConfig,
    TParticipantCoachingMomentChatEntityRef as TEntityRef
} from "./types"

type TProps = {
    wrapperClassName?: string
    config: TChatConfig
}

const classes: { section(): string } = { section: (): string => "flex flex-col gap-y-[10px] " }

const Entity: FC<TProps> = forwardRef<TEntityRef, TProps>(
    ({ wrapperClassName = String(), config }: TProps, ref: ForwardedRef<TEntityRef>): ReactElement => (
        <section className={classNames(classes.section(), { [wrapperClassName]: wrapperClassName })}>
            <Provider {...config} {...{ ref }} />
        </section>
    )
)

Entity.displayName = "ParticipantCoachingMomentChatEntity"

function propsAreEqual(prevProps: TProps, nextProps: TProps): boolean {
    return isEqual(nextProps, prevProps)
}

const MemoizedEntity: NamedExoticComponent<TProps> = memo(Entity, propsAreEqual)

export { MemoizedEntity as ParticipantCoachingMomentChatEntity }
