import { type FC, type ReactElement } from "react"

import { LockIcon } from "@/3514/assets"
import { ETypographyFontWeight, ETypographySize, ETypographyTag, Typography } from "@/3514/components"
import { type IUseTranslation, useTranslation } from "@/hooks"

import { participantCoachingMomentOwlComponents as owls } from "./participant-coaching-moment-chat-owls-components"

const Banner: FC = (): ReactElement => {
    const { t }: IUseTranslation = useTranslation()

    return (
        <div className="flex items-center px-[12px] py-[12px] rounded-[10px] bg-chat-locked w-full gap-x-[12px]">
            <owls.DefaultLogo />
            <div className="block md:flex gap-x-[10px]">
                <div className="flex gap-x-[5px]">
                    <LockIcon />
                    <Typography
                        text={t("Locked")}
                        size={ETypographySize.Small}
                        weight={ETypographyFontWeight.Bold}
                        tag={ETypographyTag.Span}
                    />
                </div>
                <Typography
                    text={t("participantSide.chapter.coachingMoment.chat.lockedChatDescription")}
                    size={ETypographySize.Small}
                    tag={ETypographyTag.Paragraph}
                />
            </div>
        </div>
    )
}

Banner.displayName = "ParticipantCoachingMomentChatBanner"

export { Banner as ParticipantCoachingMomentChatBanner }
