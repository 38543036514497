import { type TEmptyCallback } from "@/shared/types/functions"

type TParticipantCoachingMomentChatSeedQuestion = {
    questionUuid: string
    question: string
    answer?: string
}

type TParticipantCoachingMomentChatConfig = {
    seedQuestions: TParticipantCoachingMomentChatSeedQuestion[]
    isChatAvailable: boolean
    chapterId: number
    chapterComponentId: number
    conversationId: number | undefined
}

enum EParticipantCoachingMomentSeedQuestionFormField {
    Id = "id",
    Index = "index",
    Answer = "answer",
    Question = "question"
}

type TParticipantCoachingMomentSeedQuestionFormField = {
    id: string
    index: number
    answer: string
    question: string
}

enum EParticipantCoachingMomentChatInputFormField {
    ChatInput = "chatInput"
}

type TParticipantCoachingMomentChatInputForm = {
    chatInput: string
}

enum EParticipantCoachingMomentChatErrorBoxVariant {
    Api = "api",
    OffTopic = "offTopic"
}

enum EParticipantCoachingMomentChatStatus {
    NotAvailable = "notAvailable",
    Locked = "locked",
    Ready = "ready",
    InProgress = "inProgress",
    Paused = "paused",
    GeneratingSummary = "generatingSummary",
    Completed = "completed"
}

type TParticipantCoachingMomentChatEntityRef = { openAbandonmentModal(onConfirm: TEmptyCallback): void }

export {
    EParticipantCoachingMomentSeedQuestionFormField,
    EParticipantCoachingMomentChatInputFormField,
    EParticipantCoachingMomentChatErrorBoxVariant,
    EParticipantCoachingMomentChatStatus,
    type TParticipantCoachingMomentSeedQuestionFormField,
    type TParticipantCoachingMomentChatInputForm,
    type TParticipantCoachingMomentChatSeedQuestion,
    type TParticipantCoachingMomentChatConfig,
    type TParticipantCoachingMomentChatEntityRef
}
