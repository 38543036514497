import { type FC, type LazyExoticComponent, lazy } from "react"

const ParticipantCoachingChatAbandonmentModal: LazyExoticComponent<FC> = lazy(
    (): Promise<{ default: FC }> =>
        import("./chat-abandonment-modal-container.component").then(
            ({ ParticipantCoachingChatAbandonmentModalContainer: C }): { default: FC } => ({
                default: C
            })
        )
)

export { ParticipantCoachingChatAbandonmentModal }
