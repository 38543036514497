import { type Dispatch, type SetStateAction, useMemo, useState } from "react"

import type { TEmptyCallback } from "@/shared/types/functions"

type TUseManualHover = {
    isActionAffected: boolean
    onMouseEnter: TEmptyCallback
    onMouseLeave: TEmptyCallback
    onFocus: TEmptyCallback
    onBlur: TEmptyCallback
    onMouseMove: TEmptyCallback
    onKeyDown: TEmptyCallback
    onTouchStart: TEmptyCallback
    onTouchEnd: TEmptyCallback
    onClick: TEmptyCallback
}

function useManualHover(): ReturnType<() => TUseManualHover> {
    const [isActionAffected, setIsActionAffected]: [boolean, Dispatch<SetStateAction<boolean>>] =
        useState<boolean>(false)
    const [isFocused, setIsFocused]: [boolean, Dispatch<SetStateAction<boolean>>] = useState<boolean>(false)

    return useMemo(
        (): TUseManualHover => ({
            isActionAffected: isActionAffected || isFocused,
            onMouseEnter: (): void => setIsActionAffected(true),
            onMouseLeave: (): void => setIsActionAffected(false),
            onFocus: (): void => setIsFocused(true),
            onBlur: (): void => setIsFocused(false),
            onMouseMove: (): void => setIsActionAffected(true),
            onTouchStart: (): void => setIsActionAffected(true),
            onTouchEnd: (): void => setIsActionAffected(false),
            onKeyDown: (): void => setIsActionAffected((prev: boolean): boolean => !prev),
            onClick: (): void => (setIsActionAffected(false), setIsFocused(false))
        }),
        [isActionAffected, isFocused]
    )
}

export { useManualHover }
export type { TUseManualHover }
