import { ReactNode, memo, useCallback, useMemo, useRef } from "react"

import classNames from "classnames"
import { useTranslation } from "react-i18next"

import useHover from "$/hooks/use-hover"
import useUpdateEffect from "$/hooks/use-update-effect"
import { isNullOrUndefined } from "$/utils/gates"

import { Status } from "@/constants"
import { type TGetValueFromTranslatedObjectFunction, useGetValueFromTranslatedObject } from "@/hooks"
import { UserEnrollModule } from "@/models/user"
import CheckMarkModuleIcon from "@/svgs/CheckMarkModule"
import LockedModule from "@/svgs/LockedModule"
import ProgressModuleDot from "@/svgs/ProgressModuleDot"
import { isUnassignedModule } from "@/utils/gates/module"

type Props = {
    isActive: boolean
    module: UserEnrollModule | null
    order?: number
    isCollapsed: boolean
    unAssignCount: number
    itemIndex: number
    removedHoverIndex?: number
    isLastItem: boolean
    className?: string
    moduleName?: string | ReactNode
    canClick?: boolean
    onHover?: (index: number) => void
    onClick: (module: UserEnrollModule, moduleIndex: number) => void
    onUnassignedClick?: () => void
}

export const ModuleItem = ({
    isActive,
    module,
    order,
    isCollapsed,
    unAssignCount,
    itemIndex,
    isLastItem,
    removedHoverIndex,
    className = "",
    moduleName,
    canClick = true,
    onClick,
    onUnassignedClick,
    onHover
}: Props) => {
    const hoverRef = useRef()
    const { t } = useTranslation()
    const hasModule = !isNullOrUndefined(module) && !isUnassignedModule(module)
    const hasUnassignedModules = !isCollapsed && unAssignCount > 0
    const isHovered = useHover(hoverRef)

    const getValueFromTranslatedObject: TGetValueFromTranslatedObjectFunction = useGetValueFromTranslatedObject()

    const onModuleItemClick = useCallback(() => {
        if (!canClick) {
            return
        }

        if (hasUnassignedModules) {
            onUnassignedClick?.()
        } else {
            onClick(module, order)
        }
    }, [hasUnassignedModules, module, order, onUnassignedClick, onClick])

    const title = useMemo(() => {
        if (hasUnassignedModules) {
            return t("+ {{unAssignCount}} More", { unAssignCount })
        }

        if (hasModule) {
            return moduleName ?? getValueFromTranslatedObject(module.name)
        }

        return moduleName ?? t("Unassigned")
    }, [hasUnassignedModules, unAssignCount, hasModule, module])

    useUpdateEffect(() => {
        if (isHovered && itemIndex > 0) {
            onHover?.(itemIndex - 1)
        } else {
            onHover?.(null)
        }
    }, [isHovered])

    const showOrder = !hasUnassignedModules && !isNullOrUndefined(order)
    const isUnassigned = showOrder && !hasModule

    return (
        <li
            className={classNames("module-navigation__item", className, { active: isActive, "not-allowed": !canClick })}
            onClick={onModuleItemClick}
            data-testid="module-navigation-item"
            ref={hoverRef}
        >
            <ModuleStatus isActive={isActive} status={hasModule ? module?.status : Status.Queued} />
            <p
                className={classNames("module-navigation__item_title", {
                    active: isActive,
                    "no-border": isHovered || removedHoverIndex === itemIndex,
                    "is-last": isLastItem
                })}
            >
                {showOrder && <span className="font-extrabold mr-1">{order}: </span>}
                <span className={classNames({ "font-italic": isUnassigned })}>{title}</span>
            </p>
        </li>
    )
}

type TStatusProps = {
    status: Status
    isActive: boolean
}

const ModuleStatus = memo(({ status, isActive }: TStatusProps) => {
    const Icon = useMemo(() => {
        const inProgressOrOpen = status === Status.InProgress || status === Status.Open
        const isQueued = status === Status.Queued
        const isCompleted = status === Status.Completed

        if (isQueued) {
            return <LockedModule isActive={isActive} />
        }

        if (isCompleted) {
            return <CheckMarkModuleIcon isActive={isActive} />
        }

        if (inProgressOrOpen) {
            return <ProgressModuleDot isActive={isActive} />
        }
    }, [status, isActive])

    return <div className="module-navigation__item_status">{Icon}</div>
})
