import { FC } from "react"

import Heading from "$/components/Heading/Heading"
import useMediaQuery from "$/hooks/use-media-query"

import { MAX_WIDTH_MOBILE_MEDIA } from "@/constants"
import { Phase } from "@/models/phase"
import { UserEnrollModule } from "@/models/user"

import { WarningMessage } from "../onboarding/components/WarningMessage"

import ModuleUnassignedStatus from "./ModuleUnassignedStatus"
import MobileModuleList from "./components/MobileModuleList"
import ResponsesItem from "./components/ResponsesItem"
import Summaries from "./components/Summaries"
import SummaryBlock from "./components/SummaryBlock"

interface IProps {
    chapter: any
    participantName: string
    enrolledModules: UserEnrollModule[]
    phasesList: Phase[]
    selectedPhase: Phase
    error?: any
    selectedModuleIndex: number
    onGoBack: () => void
    onSelectModule: (option) => void
    onSelectPhase: (phase: Phase) => void
}

const ParticipantResponses: FC<IProps> = ({
    chapter,
    participantName,
    enrolledModules,
    selectedPhase,
    phasesList,
    selectedModuleIndex,
    error,
    onGoBack,
    onSelectModule,
    onSelectPhase
}) => {
    const matches = useMediaQuery(MAX_WIDTH_MOBILE_MEDIA)

    return (
        <div className="participant-responses">
            {matches ? (
                <MobileModuleList
                    onSelectModule={onSelectModule}
                    onSelectPhase={onSelectPhase}
                    enrolledModules={enrolledModules}
                    selectedPhase={selectedPhase}
                    phasesList={phasesList}
                />
            ) : chapter ? (
                <div className="responses-container">
                    <h1 className="h2 font-extrabold font-italic text-center mb-4">
                        {participantName}&apos;s Assessment Results
                    </h1>
                    {selectedPhase?.chapters?.map(chapter => (
                        <>
                            {chapter?.show_summary_after_finish && (
                                <SummaryBlock
                                    shortSummary={chapter.chapter_summary.short_summary}
                                    summary={chapter.chapter_summary.summary}
                                    // summarySubtitle={chapter.chapter_summary.summary_subtitle}
                                />
                            )}
                            {chapter.components.map(component => (
                                <Summaries component={component} />
                            ))}
                        </>
                    ))}

                    {selectedPhase?.chapters?.map(chapter => (
                        <ResponsesItem chapter={chapter} participantName={participantName} />
                    ))}
                    <div className="text-center">
                        <button className="btn btn-next btn-back btn--brand-outline" onClick={onGoBack}>
                            Back
                        </button>
                    </div>
                </div>
            ) : (
                <>
                    <div className="d-flex align-items-center justify-content-center mb-4">
                        <Heading tag="h2" fontSize={36} className="m-0">
                            Module {selectedModuleIndex}:
                        </Heading>
                        <span className="fs-36"> Unassigned</span>
                    </div>
                    <ModuleUnassignedStatus text="You may only assign modules while taking attendance after regularly scheduled program sessions, otherwise participants are empowered to assign themselves a module of their choosing. At this time, a participant may not take the same module twice." />
                </>
            )}
            {error ? <WarningMessage message={error} /> : null}
        </div>
    )
}

export default ParticipantResponses
