import { type FC, type ReactElement, startTransition, useEffect } from "react"

import classNames from "classnames"
import { type NavigateFunction, type Params, useNavigate, useParams } from "react-router-dom"

import useMediaQuery from "$/hooks/use-media-query"
import { isEmpty } from "$/utils/gates"

import { LoadableComponent } from "@/3514/components"
import { ERoutePaths } from "@/3514/constants"
import { ParticipantHeaderEntity as Header } from "@/3514/entities"
import { EParticipantHeaderVariant } from "@/3514/entities/participant-header/participant-header.ui.types"
import { EModalId } from "@/3514/store/slices"
import { MAX_WIDTH_MOBILE_MEDIA } from "@/constants"
import { type TUseModal, useModal } from "@/hooks"
import { AnalyticsModal, SessionAttendanceModal } from "@/modals"

import { type TChapterByIdQuery, useChapterByIdQuery } from "../api"

import { ParticipantChapterContentEntity as Content } from "./entities"

const classes: { container(): string; header(isNotDesktop: boolean): string } = {
    container: (): string => "flex flex-col",
    header: (isNotDesktop: boolean): string =>
        classNames("flex m-auto max-w-[540px] shadow-sm", isNotDesktop && "overflow-hidden w-full")
}

const Container: FC = (): ReactElement => {
    const { getModal }: TUseModal = useModal()

    const { chapterId }: Readonly<Params> = useParams()

    const { data: chapterData, isFetching: isChapterDataFetching }: TChapterByIdQuery = useChapterByIdQuery({
        id: +chapterId,
        enabled: Boolean(chapterId),
        staleTime: Infinity
        // refetchOnMount: "always"
    })

    const isMobile: boolean = useMediaQuery(MAX_WIDTH_MOBILE_MEDIA)
    const isTablet: boolean = useMediaQuery("(max-width: 1199px)")

    const navigate: NavigateFunction = useNavigate()

    useEffect(
        (): void => (
            !isEmpty(chapterData?.redirectChapterId) &&
                startTransition((): void =>
                    navigate(`${ERoutePaths.CHAPTER}/${chapterData.redirectChapterId}`, { replace: true })
                ),
            void 0
        ),
        [chapterData?.redirectChapterId, navigate]
    )

    return (
        <div className={classes.container()}>
            <div className={classes.header(isMobile || isTablet)}>
                <Header componentRendererProps={{ withTitle: false }} variant={EParticipantHeaderVariant.Chapter} />
            </div>

            <LoadableComponent
                component={Content}
                componentProps={{ chapter: chapterData }}
                isReady={!isEmpty(chapterData)}
                isLoading={isChapterDataFetching}
            />

            <LoadableComponent isReady={!isEmpty(getModal(EModalId.AnalyticsDashboard))} component={AnalyticsModal} />

            <LoadableComponent
                isReady={!isEmpty(getModal(EModalId.SessionAttendance))}
                component={SessionAttendanceModal}
            />
        </div>
    )
}

Container.displayName = "ParticipantChapterContainer"

export { Container as ParticipantChapterContainer }
