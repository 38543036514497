import type { QueryFunctionContext } from "@tanstack/query-core"
import type { AxiosResponse } from "axios"

import { http } from "$/http"

import {
    EParticipantCoachingMomentChatApiQueryKey as EQueryKey,
    EParticipantCoachingMomentChatApiQueryUrl as EUrl
} from "../../../config"
import type { IParticipantCoachingMomentChatNewMessagesDto as IDto } from "../../../types"

async function fetcher({ queryKey }: QueryFunctionContext<[EQueryKey, number]>): Promise<IDto> {
    const [, id] = queryKey
    const { data }: AxiosResponse<IDto> = await http.get(EUrl.GetConversationNewMessages(id))

    return data
}

export { fetcher as coachingMomentChatNewMessagesQueryFetcher }
