import type { FC, ReactElement } from "react"

import { ParticipantLayout as Layout } from "@/layouts"
import { LeftSidebar as Sidebar } from "@/shared/participant-left-sidebar"
import { ESidebarTag } from "@/shared/sidebar/Sidebar"

import { ParticipantChapterContainer as Container } from "."

/**
 * Participant' Chapter page. Design: {@link https://www.figma.com/design/J6qF3Pa6zeK3udtVWLskjd/Participant?node-id=13200-158580&m=dev}
 * @return ReactElement
 */
const Page: FC = (): ReactElement => (
    <Layout tag="main" leftSidebar={<Sidebar tag={ESidebarTag.Aside} />}>
        <Container />
    </Layout>
)

Page.displayName = "ParticipantChapterPage"

export { Page as ParticipantChapterPage }
