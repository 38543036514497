import type { FC, ReactElement } from "react"

import classNames from "classnames"

type TProps = { isAccent?: boolean; className?: string }

const Icon: FC<TProps> = ({ isAccent = false, className = String() }: TProps): ReactElement => (
    <svg
        className={classNames({ [className]: className })}
        width="15"
        height="15"
        viewBox="0 0 15 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M0.0118165 14.3042L1.27923 8.81753C1.32929 8.58404 1.52939 8.40061 1.77954 8.36728L8.86715 7.63349C9.06724 7.61683 9.06724 7.31662 8.86715 7.2833L1.77954 6.59957C1.52939 6.5829 1.32929 6.39947 1.27923 6.16598L0.0118165 0.695875C-0.104898 0.228954 0.395409 -0.154624 0.82899 0.0621871L14.6874 6.99971C15.1043 7.21653 15.1043 7.81688 14.6874 8.03364L0.82899 14.9378C0.395409 15.1546 -0.104893 14.771 0.0118165 14.3041V14.3042Z"
            fill={isAccent ? "#0404CB" : "#B0B0B0"}
        />
    </svg>
)

Icon.displayName = "ParticipantCoachingMomentChatOwlLetterIcon"

export { Icon as ParticipantCoachingMomentChatOwlLetterIcon }
