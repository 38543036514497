import type { ReactElement } from "react"

import type { TranslationObj } from "$/utils/lang"

import type { TEmptyCallback } from "@/shared/types/functions"

enum EMode {
    View = "view",
    Edit = "edit"
}

type TProps = {
    content?: TranslationObj | string
    fallbackLoader?: ReactElement
    mode?: EMode
    onChange?(data: string): void | TEmptyCallback
    key?: string // for multi-tab admin case
    isEditorForceSkipped?: boolean
    cssConfig?: {
        classNameIdentifier?: string
        classNamesToOverride?: string
        editorClassName?: string
    }
}

export { EMode as ERichTextRendererMode, type TProps as TRichTextRendererProps }
