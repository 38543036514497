import { type FC, type ReactElement } from "react"

import type { IAvailableGuestSessionModel } from "@/pages/swap-session/api"
import type { TEmptyCallback } from "@/shared/types/functions"

import type { IAvailableSessionDataGroupedByDay } from "./types"

import { SwapSessionAccordion as Accordion } from "."

type TSwapSessionAccordionListProps = {
    sessions?: IAvailableSessionDataGroupedByDay[]
    handleSetSuggestedSession(session: IAvailableGuestSessionModel): void
    handleOpenSwapSessionModal: TEmptyCallback
}

const SwapSessionAccordionList: FC<TSwapSessionAccordionListProps> = ({
    sessions,
    handleSetSuggestedSession,
    handleOpenSwapSessionModal
}: TSwapSessionAccordionListProps): ReactElement => (
    <div className="swap-session__accordion__list">
        {sessions.map(
            (s: IAvailableSessionDataGroupedByDay): ReactElement => (
                <Accordion
                    key={`slide-accordion-${s.weekDay}`}
                    handleSetSuggestedSession={handleSetSuggestedSession}
                    handleOpenSwapSessionModal={handleOpenSwapSessionModal}
                    session={s}
                />
            )
        )}
    </div>
)

export { SwapSessionAccordionList, type TSwapSessionAccordionListProps }
