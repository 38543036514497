import type { FC, ForwardRefExoticComponent, PropsWithoutRef, ReactNode, RefAttributes } from "react"

import { EBadgeVariant } from "@/3514/components"

import type { IModuleExcerptModel } from "./types"

type TJourneyAchievementModelToRender = {
    icon: ReactNode
    label: string
    badgeVariant: EBadgeVariant
    badgeAccentText: string
    badgeOptionalText?: string
}

enum EParticipantHeaderComponentLayout {
    Page = "page",
    AnalyticsModal = "AnalyticsModal"
}

type TParticipantHeaderComponentRenderProps<TCustomProps extends Object = {}> = {
    renderConfig: {
        layout?: EParticipantHeaderComponentLayout
        titleWrapper?: FC<{ children: ReactNode }>
        contentWrapper?:
            | ForwardRefExoticComponent<PropsWithoutRef<{ children: ReactNode }> & RefAttributes<HTMLDivElement>>
            | FC<{ children: ReactNode }>
        componentWrapper?: FC<{ children: ReactNode }>
    } & TCustomProps
}

enum EParticipantHeaderComponentName {
    NextSession = "NextSession",
    ModulesExcerpts = "ModulesExcerpts",
    JourneyAchievements = "JourneyAchievements"
}

enum EParticipantHeaderVariant {
    Dashboard = "Dashboard",
    Chapter = "Chapter"
}

type TModuleExcerptModelToRender = IModuleExcerptModel & { isStub?: boolean }

export { EParticipantHeaderComponentName, EParticipantHeaderComponentLayout, EParticipantHeaderVariant }
export type { TJourneyAchievementModelToRender, TParticipantHeaderComponentRenderProps, TModuleExcerptModelToRender }
