import { type FC, type ReactElement, ReactNode } from "react"

import classNames from "classnames"

import { typographyConfig } from "./typography.config"

import { ETypographyAlignment, ETypographyColor, ETypographyFontWeight, ETypographySize, ETypographyTag } from "."

const { sizesMap, colorsMap, fontWeightsMap, alignmentMap } = typographyConfig

type TTypographyProps = {
    tag?: ETypographyTag
    size?: ETypographySize
    color?: ETypographyColor
    weight?: ETypographyFontWeight
    alignment?: ETypographyAlignment
    isItalic?: boolean
    className?: string
    text: string | ReactNode
}

const Component: FC<TTypographyProps> = ({
    tag: Tag = ETypographyTag.Paragraph,
    size = ETypographySize.Medium,
    color = ETypographyColor.Black,
    weight = ETypographyFontWeight.Default,
    alignment = ETypographyAlignment.Left,
    isItalic: italic = false,
    className,
    text
}: TTypographyProps): ReactElement => (
    <Tag
        className={classNames(className, {
            "mb-[0px]": Tag === ETypographyTag.Paragraph, // reset default margin
            [sizesMap[size]]: size,
            [colorsMap[color]]: color,
            [fontWeightsMap[weight]]: weight,
            [alignmentMap[alignment]]: alignment,
            italic
        })}
    >
        {text}
    </Tag>
)

Component.displayName = "Typography"

export { Component as Typography, type TTypographyProps }
