function getAppearanceAnimationCssString(
    config: { identifier: string; scaleTo?: number; durationInMs?: number } = {
        identifier: String()
    }
): string {
    const defaultConfig = { scaleTo: 0.9, durationInMs: 250 }

    const renderConfig = { ...defaultConfig, ...config }

    return `
      .${renderConfig.identifier}-enter {
        opacity: 0;
        transform: scale(${renderConfig.scaleTo});
      }
      .${renderConfig.identifier}-enter-active {
        opacity: 1;
        transform: scale(1);
        transition: opacity ${renderConfig.durationInMs}ms, transform ${renderConfig.durationInMs}ms;
      }
      .${renderConfig.identifier}-exit {
        opacity: 1;
        transform: scale(1);
      }
      .${renderConfig.identifier}-exit-active {
        opacity: 0;
        transform: scale(${renderConfig.scaleTo});
        transition: opacity ${renderConfig.durationInMs}ms, transform ${renderConfig.durationInMs}ms;
      }
    `
}

export { getAppearanceAnimationCssString }
