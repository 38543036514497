import {
    type ChangeEvent,
    type FC,
    type KeyboardEvent,
    type MutableRefObject,
    type ReactElement,
    useCallback,
    useRef
} from "react"

import classNames from "classnames"
import { type UseFormReturn, useForm } from "react-hook-form"
import { CSSTransition } from "react-transition-group"

import { isEmpty } from "$/utils/gates"

import {
    Button,
    EButtonSize,
    EButtonVariant,
    ETypographyColor,
    ETypographySize,
    ETypographyTag,
    TextArea,
    Typography
} from "@/3514/components"
import { getAppearanceAnimationCssString } from "@/3514/utils"
import { type IUseTranslation, useCSSInsertion, useTranslation } from "@/hooks"
import { type TEmptyAsyncCallback } from "@/shared/types/functions"

import { ParticipantCoachingMomentChatOwlLetterIcon as LetterIcon } from "../assets"
import { useParticipantCoachingMomentChatContext as useChatContext } from "../context"
import {
    EParticipantCoachingMomentChatStatus as EChatStatus,
    EParticipantCoachingMomentChatInputFormField as EFormField,
    EParticipantCoachingMomentChatMessageAuthor as EMessageAuthor,
    type TParticipantCoachingMomentChatInputForm as TForm
} from "../types"
import { participantCoachingMomentChatInputFormResolver as formResolver } from "../validation"

const classes = {
    field: "bg-white rounded-[10px] mt-[5px] mx-[10px] mb-[10px]",
    container: (hasError: boolean): string =>
        classNames(
            ["rounded-[10px]", "flex", "flex-col", "gap-y-[13px]", "relative"],
            hasError && "cursor-not-allowed"
        ),
    infoBox: "flex items-end gap-x-[5px] absolute right-[5px] bottom-[5px] p-[3px] bg-white/70",
    input: [
        "placeholder:italic",
        "pt-[12px]",
        "pr-[8px]",
        "pb-[8px]",
        "pl-[15px]",
        "rounded-[8px]",
        "!border-[1px]",
        "focus:!border-[1px]",
        "hover:!border-[1px]",
        "active:!border-[1px]",
        "!border-t-transparent",
        "h-[88px]",
        "no-scrollbar",
        "caret-gray-300"
    ].join(" "),
    hint: "pb-[2px] select-none",
    infoBoxIconWrapper: "border-[1px] border-transparent focus:border-accent pl-[7px]"
}

const Component: FC = (): ReactElement => {
    const { t }: IUseTranslation = useTranslation()

    const {
        chat: {
            hasError,
            conversationId,
            status,
            messages,
            createMessageMutation: { mutateAsync, isLoading: isMessageSending }
        }
    }: ReturnType<typeof useChatContext> = useChatContext()

    const {
        watch,
        setValue,
        reset: resetForm,
        handleSubmit,
        formState: { isValid: isFormValid }
    }: UseFormReturn<TForm> = useForm<TForm>({
        resolver: formResolver,
        reValidateMode: "onChange",
        criteriaMode: "all",
        defaultValues: { [EFormField.ChatInput]: String() }
    })

    const isChatInProgress: boolean = [
        EChatStatus.InProgress,
        EChatStatus.Ready,
        EChatStatus.Paused,
        EChatStatus.GeneratingSummary
    ].includes(status)

    const handleMessageSend: (values: TForm) => Promise<void> = useCallback(
        async (values: TForm): Promise<void> => (
            await mutateAsync(
                { conversationId, message: values[EFormField.ChatInput] },
                {
                    onSuccess: async (): Promise<void> => (
                        resetForm({ [EFormField.ChatInput]: String() }), setValue(EFormField.ChatInput, String())
                    )
                }
            ),
            void 0
        ),
        [conversationId, mutateAsync, resetForm, setValue]
    )

    const handleFormSubmit: TEmptyAsyncCallback = handleSubmit(handleMessageSend)

    const inputValue: string = watch(EFormField.ChatInput)

    const handleInputChange: (e: ChangeEvent<HTMLTextAreaElement>) => void = (
        e: ChangeEvent<HTMLTextAreaElement>
    ): void =>
        setValue(EFormField.ChatInput, e.target.value, {
            shouldTouch: true,
            shouldValidate: true
        })

    const handleInputKeyDown: (e: KeyboardEvent) => void = async (event: KeyboardEvent): Promise<void> => {
        if (event.key === "Enter") {
            event.preventDefault()
            await handleFormSubmit()
        }

        return void 0
    }

    const formRef: MutableRefObject<HTMLFormElement> = useRef<HTMLFormElement>(null)

    const inputRef: MutableRefObject<HTMLTextAreaElement> = useRef<HTMLTextAreaElement>(null)

    const shouldInputBeLocked: boolean =
        (status !== EChatStatus.Completed &&
            (messages[messages.length - 1]?.author === EMessageAuthor.User || isEmpty(messages))) ||
        status === EChatStatus.GeneratingSummary

    const isTextareaDisabled: boolean = shouldInputBeLocked

    const isButtonDisabled: boolean = !isFormValid || shouldInputBeLocked

    const animationClassNameId: string = "pcmcic"

    useCSSInsertion({
        cssString: getAppearanceAnimationCssString({
            identifier: animationClassNameId,
            durationInMs: 150,
            scaleTo: 0.85
        })
    })

    return (
        <CSSTransition
            timeout={250}
            classNames={animationClassNameId}
            in={isChatInProgress}
            nodeRef={formRef}
            mountOnEnter
            unmountOnExit
        >
            <form tabIndex={-1} ref={formRef} onSubmit={handleFormSubmit} className={classes.field}>
                <div className={classes.container(hasError)}>
                    <TextArea
                        ref={inputRef}
                        textColor={ETypographyColor.Black}
                        textSize={ETypographySize.Medium}
                        withResizeDisabled
                        value={inputValue}
                        onKeyDown={handleInputKeyDown}
                        onChange={handleInputChange}
                        className={classes.input}
                        isDisabled={isTextareaDisabled}
                        {...(!isTextareaDisabled && {
                            placeholder: t("participantSide.chapter.coachingMoment.chat.placeholder")
                        })}
                    />
                    <div className={classes.infoBox}>
                        <Typography
                            text={t("participantSide.chapter.coachingMoment.chat.hint")}
                            color={ETypographyColor.DarkGray}
                            tag={ETypographyTag.Span}
                            size={ETypographySize.ExtraSmall}
                            className={classes.hint}
                            isItalic
                        />
                        <Button
                            tabIndex={0}
                            variant={EButtonVariant.EmptyTransparent}
                            size={EButtonSize.Container}
                            isDisabled={isButtonDisabled}
                            isBusy={isMessageSending}
                            className={classes.infoBoxIconWrapper}
                        >
                            <LetterIcon isAccent={isFormValid} className="cursor-pointer" />
                        </Button>
                    </div>
                </div>
            </form>
        </CSSTransition>
    )
}

Component.displayName = "ParticipantCoachingMomentChatInput"

export { Component as ParticipantCoachingMomentChatInput }
