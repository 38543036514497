import { type LazyExoticComponent, type NamedExoticComponent, lazy } from "react"

import type { TParticipantChapterContentEntityProps } from "./participant-chapter-content.entity"

const ParticipantChapterContentEntity: LazyExoticComponent<
    NamedExoticComponent<TParticipantChapterContentEntityProps>
> = lazy(
    (): Promise<{ default: NamedExoticComponent<TParticipantChapterContentEntityProps> }> =>
        import("./participant-chapter-content.entity").then(
            ({
                ParticipantChapterContentEntity: E
            }): { default: NamedExoticComponent<TParticipantChapterContentEntityProps> } => ({
                default: E
            })
        )
)

export { ParticipantChapterContentEntity }
export type { TParticipantChapterContentEntityProps }
