const cssInsertionStyles: string = `
.video-renderer iframe { 
  @media screen and (max-width: 1199px) {
    aspect-ratio: 16 / 9;
    height: unset !important;
  }
}
`

const config = { cssInsertionStyles }

export { config as videoRendererConfig }
