import {
    type Context,
    type FC,
    type ForwardedRef,
    Fragment,
    type MutableRefObject,
    type ReactElement,
    createContext,
    forwardRef,
    useContext,
    useEffect,
    useImperativeHandle,
    useMemo,
    useRef
} from "react"

import { QueryClient, useQueryClient } from "@tanstack/react-query"
import { CSSTransition } from "react-transition-group"

import { isEmpty } from "$/utils/gates"

import { LoadableComponent } from "@/3514/components"
import { EModalId } from "@/3514/store/slices"
import { getAppearanceAnimationCssString } from "@/3514/utils"
import { ServerStateKeys as EQueryKey } from "@/constants"
import { type TUseModal, useCSSInsertion, useModal } from "@/hooks"
import { LocalStorageServiceKeys, StorageService } from "@/services"
import { type TEmptyCallback } from "@/shared/types/functions"

import {
    type TCoachingMomentChatConversationQuery as TConversationQuery,
    type TCreateCoachingMomentConversationMutation as TCreateConversationMutation,
    type TCreateCoachingMomentConversationMutationResponse as TCreateConversationMutationResponse,
    type TCreateCoachingMomentChatMessageMutation as TCreateMessageMutation,
    type TCreateCoachingMomentChatMessageMutationParams as TCreateMessageMutationParams,
    type TCreateCoachingMomentChatMessageMutationResponse as TCreateMessageMutationResponse,
    useCoachingMomentChatConversationQuery as useConversationQuery,
    useCreateCoachingMomentConversationMutation as useCreateConversationMutation,
    useCreateCoachingMomentChatMessageMutation as useCreateMessageMutation,
    useCoachingMomentChatNewMessagesQuery as useNewMessagesQuery
} from "../api"
import {
    ParticipantCoachingMomentChatBanner as Banner,
    ParticipantCoachingMomentChat as Chat,
    ParticipantCoachingMomentChatFields as FieldList
} from "../components"
import {
    EParticipantCoachingMomentChatApiQueryKey as EChatQueryKey,
    NEW_MESSAGES_QUERY_LONG_POLLING_REFETCH_INTERVAL_IN_MS as LONG_POLLING_INTERVAL,
    NEW_MESSAGES_QUERY_SHORT_POLLING_REFETCH_INTERVAL_IN_MS as SHORT_POLLING_INTERVAL
} from "../config"
import { ParticipantCoachingChatAbandonmentModal as ChatAbandonmentModal } from "../modals"
import {
    type TParticipantCoachingMomentChatState as TChatState,
    type TUseParticipantCoachingMomentChatState as TUseChatState,
    useParticipantCoachingMomentChatState as useChatState
} from "../state"
import {
    EParticipantCoachingMomentChatMessageAuthor as EChatMessageAuthor,
    EParticipantCoachingMomentChatStatus as EChatStatus,
    type IParticipantCoachingMomentChatMessageModel as IConversationMessageModel,
    type IParticipantCoachingMomentChatNewMessagesModel as INewMessagesModel,
    type TParticipantCoachingMomentChatConfig as TChatConfig,
    type TParticipantCoachingMomentChatEntityRef as TEntityRef,
    type TParticipantCoachingMomentSeedQuestionFormField as TQuestionFormField
} from "../types"
import {
    participantCoachingMomentChatApiUtils as apiUtils,
    participantCoachingMomentChatUiUtils as uiUtils
} from "../utils"

const {
    mapContextFieldsValuesToCreateConversationMutationParams: mapMutationParams,
    getConversationStatusEnumFromType: getConversationStatus
} = apiUtils
const { mapSeedQuestionsToFormFields } = uiUtils

type TChatContext = {
    form: { questions: TQuestionFormField[]; wasSubmitted: boolean } & {
        handleQuestionSubmit: Pick<TUseChatState, "setQuestionAnswer">["setQuestionAnswer"]
    }
    chat: TChatState["chat"] &
        Pick<TUseChatState, "changeStatus"> & {
            createMessageMutation: TCreateMessageMutation
        }
}

type TProps = TChatConfig

const storageService: StorageService = new StorageService()

const ChatContext: Context<TChatContext> = createContext<TChatContext | undefined>(undefined)

function useChatContext(): TChatContext {
    const context: TChatContext = useContext(ChatContext)

    if (!context) {
        throw new Error(
            "useParticipantCoachingMomentChatContext must be used within a ParticipantCoachingMomentChatProvider"
        )
    }

    return context
}

const ChatProvider: FC<TProps> = forwardRef(
    (
        {
            seedQuestions,
            isChatAvailable,
            chapterId,
            chapterComponentId,
            conversationId: existingConversationId
        }: TProps,
        entityRef: ForwardedRef<TEntityRef>
    ): ReactElement => {
        const queryClient: QueryClient = useQueryClient()

        const {
            state: { chat: chatState, form: formState },
            addNewMessages,
            setQuestionAnswer,
            loadQuestionsAnswers,
            setConversationId,
            loadConversation,
            triggerError,
            changeStatus,
            sendMessage,
            removeMessage
        }: TUseChatState = useChatState()

        useEffect(
            (): void => (
                !isEmpty(seedQuestions) && loadQuestionsAnswers(mapSeedQuestionsToFormFields(seedQuestions)),
                !isEmpty(existingConversationId) && setConversationId(existingConversationId),
                !isChatAvailable && changeStatus(EChatStatus.NotAvailable),
                void 0
            ),
            [
                existingConversationId,
                isChatAvailable,
                seedQuestions,
                changeStatus,
                setConversationId,
                loadQuestionsAnswers
            ]
        )

        const createMessageMutation: TCreateMessageMutation = useCreateMessageMutation({
            // @ts-expect-error no error here, just types mess
            onMutate: async ({
                conversationId: createdMessageConversationId,
                message: createdMessageText
            }: TCreateMessageMutationParams): Promise<{
                optimisticMessage: IConversationMessageModel
            }> => {
                const optimisticMessage: IConversationMessageModel = {
                    id: Date.now(),
                    conversationId: Number(createdMessageConversationId),
                    message: createdMessageText,
                    author: EChatMessageAuthor.User,
                    conversation: null
                }

                sendMessage({ message: optimisticMessage })

                return { optimisticMessage }
            },
            onSuccess: async (
                response: TCreateMessageMutationResponse,
                _vars: void,
                context: { optimisticMessage: IConversationMessageModel }
            ): Promise<void> =>
                sendMessage({
                    responseMessageId: response.data.message_id,
                    optimisticMessageId: context.optimisticMessage.id
                }),
            onError: async (
                _err: unknown,
                _vars: void,
                context: { optimisticMessage: IConversationMessageModel }
            ): Promise<void> => (console.log(_err), removeMessage(context.optimisticMessage.id), triggerError(true))
        })

        const {
            mutateAsync: handleCreateConversation,
            isSuccess: isCreateConversationMutationSuccess
        }: TCreateConversationMutation = useCreateConversationMutation({
            retry: false
        })

        const { data: chatConversationData }: TConversationQuery = useConversationQuery({
            conversationId: chatState.conversationId,
            enabled: !isEmpty(chatState.conversationId) && !isCreateConversationMutationSuccess,
            retry: false,
            staleTime: chatState.status !== EChatStatus.Completed ? 0 : Infinity,
            refetchOnMount: chatState.status !== EChatStatus.Completed ? "always" : false,
            onError: async (): Promise<void> => triggerError(true)
        })

        useNewMessagesQuery({
            conversationId: chatState.conversationId,
            enabled: [EChatStatus.InProgress].includes(chatState.status),
            retry: false,
            refetchInterval: isEmpty(chatState.messages)
                ? SHORT_POLLING_INTERVAL
                : chatState.messages[chatState.messages.length - 1]?.author === EChatMessageAuthor.User
                  ? SHORT_POLLING_INTERVAL
                  : LONG_POLLING_INTERVAL,
            staleTime: 0,
            cacheTime: 0,
            onSuccess: async (fetchedMessages: INewMessagesModel): Promise<void> => addNewMessages(fetchedMessages)
        })

        useEffect(
            (): void => (
                !isEmpty(chatConversationData) && loadConversation({ conversation: chatConversationData }), void 0
            ),
            [chatConversationData, loadConversation]
        )

        useEffect(
            (): TEmptyCallback => (): void => (
                isCreateConversationMutationSuccess &&
                    queryClient.invalidateQueries([EQueryKey.ChapterById, chapterId]),
                void 0
            ),
            [chapterId, isCreateConversationMutationSuccess, queryClient]
        )

        useEffect(
            (): void => (
                formState.isSubmitted && !formState.wasSubmitted && !isCreateConversationMutationSuccess
                    ? handleCreateConversation(mapMutationParams(formState.questions, chapterId, chapterComponentId), {
                          onSuccess: async ({
                              data: responseData
                          }: TCreateConversationMutationResponse): Promise<void> =>
                              !isEmpty(responseData) &&
                              (loadConversation({
                                  conversation: {
                                      id: responseData.id,
                                      summary: responseData.summary,
                                      status: getConversationStatus(responseData.status as never),
                                      completedStamp: responseData.completed_stamp,
                                      messages: []
                                  },
                                  status: EChatStatus.InProgress
                              }),
                              queryClient.invalidateQueries([
                                  EChatQueryKey.CoachingMomentChatConversation,
                                  responseData.id
                              ]),
                              void 0),
                          onSettled: async (): Promise<void> =>
                              formState.questions.forEach((q: TQuestionFormField): void =>
                                  storageService.removeItem(LocalStorageServiceKeys.CoachingMomentSeedQuestion(q.id))
                              )
                      })
                    : void 0,
                void 0
            ),
            [
                handleCreateConversation,
                chapterId,
                chapterComponentId,
                formState.isSubmitted,
                formState.questions,
                chatState.conversationId,
                loadConversation,
                formState,
                changeStatus,
                isCreateConversationMutationSuccess,
                queryClient
            ]
        )

        const providerValue: ReturnType<typeof useChatContext> = useMemo(
            (): ReturnType<typeof useChatContext> => ({
                form: {
                    questions: formState.questions,
                    wasSubmitted: formState.wasSubmitted,
                    handleQuestionSubmit: setQuestionAnswer
                },
                chat: {
                    ...chatState,
                    changeStatus,
                    createMessageMutation
                }
            }),
            [
                formState.questions,
                formState.wasSubmitted,
                setQuestionAnswer,
                chatState,
                changeStatus,
                createMessageMutation
            ]
        )

        const chatRef: MutableRefObject<HTMLDivElement> = useRef<HTMLDivElement>()

        const chatAnimationIdentifier: string = "pcmchat"

        useCSSInsertion({
            cssString: getAppearanceAnimationCssString({
                identifier: chatAnimationIdentifier,
                durationInMs: 400,
                scaleTo: 0.9
            })
        })

        const { showModal, getModal }: TUseModal = useModal()

        useImperativeHandle(
            entityRef,
            (): TEntityRef => ({
                openAbandonmentModal: (onConfirm: TEmptyCallback): void =>
                    [EChatStatus.InProgress, EChatStatus.Ready].includes(chatState.status)
                        ? showModal(EModalId.CoachingChatAbandonment, { onConfirm })
                        : onConfirm()
            }),
            [chatState.status, showModal]
        )

        return (
            <ChatContext.Provider value={providerValue}>
                <Fragment>
                    <FieldList />

                    {chatState.status === EChatStatus.Locked ? <Banner /> : null}

                    <CSSTransition
                        timeout={400}
                        nodeRef={chatRef}
                        classNames={chatAnimationIdentifier}
                        in={
                            isEmpty(existingConversationId)
                                ? chatState.status !== EChatStatus.NotAvailable &&
                                  chatState.status !== EChatStatus.Locked
                                : chatState.status !== EChatStatus.NotAvailable
                        }
                        mountOnEnter
                        unmountOnExit
                    >
                        <Chat ref={chatRef} />
                    </CSSTransition>
                </Fragment>

                <LoadableComponent
                    isReady={!isEmpty(getModal(EModalId.CoachingChatAbandonment))}
                    component={ChatAbandonmentModal}
                />
            </ChatContext.Provider>
        )
    }
)

ChatProvider.displayName = "ParticipantCoachingMomentChatProvider"

export {
    ChatProvider as ParticipantCoachingMomentChatProvider,
    useChatContext as useParticipantCoachingMomentChatContext
}
