import { type Dispatch, type FC, type KeyboardEvent, type ReactElement, type SetStateAction, useState } from "react"

import classNames from "classnames"

import { type TUseManualHover, useManualHover } from "@/hooks"
import { type TEmptyCallback, emptyCallback } from "@/shared/types/functions"

import { checkboxConfig as config } from "./checkbox.config"
import { ECheckboxVariant } from "./checkbox.types"

const { colorMap } = config

type TProps = { variant?: ECheckboxVariant; onChange?(isChecked: boolean): void; checked?: boolean; ariaLabel?: string }

const Component: FC<TProps> = ({
    variant = ECheckboxVariant.Default,
    checked = false,
    onChange = emptyCallback,
    ariaLabel = String()
}: TProps): ReactElement => {
    const { onTouchStart, onTouchEnd, onMouseLeave, onMouseEnter, isActionAffected }: TUseManualHover = useManualHover()

    const [isChecked, setIsChecked]: [boolean, Dispatch<SetStateAction<boolean>>] = useState<boolean>(checked)

    const { wrapper: wrapperClasses, indicator: indicatorClasses } = colorMap[variant](isActionAffected, isChecked)

    const toggleCheckbox: TEmptyCallback = (): void => (setIsChecked(!isChecked), onChange?.(!isChecked))

    return (
        <div
            aria-label={ariaLabel}
            role="checkbox"
            aria-checked={isChecked}
            className={classNames(wrapperClasses)}
            tabIndex={0}
            onMouseLeave={onMouseLeave}
            onMouseEnter={onMouseEnter}
            onTouchStart={onTouchStart}
            onTouchEnd={onTouchEnd}
            onClick={toggleCheckbox}
            onKeyDown={(e: KeyboardEvent): void => e.key === "Enter" && toggleCheckbox()}
        >
            <i className={classNames(indicatorClasses)} />
        </div>
    )
}

Component.displayName = "Checkbox"

export { Component as Checkbox, type TProps as TCheckboxProps }
