import { storageUtils } from "./utils"

const SessionStorageServiceKeys: {
    UserLogout: () => string
    FakeParticipantTokenAccess: () => string
    FakeParticipantTokenRefresh: () => string
    FakeParticipantsFeatureEnabled: () => string
} = {
    UserLogout: (): string => "userLoggedOut",
    FakeParticipantTokenAccess: (): string => "fakeParticipantTokenAccess",
    FakeParticipantTokenRefresh: (): string => "fakeParticipantTokenRefresh",
    FakeParticipantsFeatureEnabled: (): string => "fakeParticipantsFeatureEnabled"
}

class SessionStorageService extends Storage {
    static setItem<T>(key: ReturnType<() => keyof typeof SessionStorageServiceKeys>, value: T): void {
        sessionStorage.setItem(key, storageUtils.setStorageItemValue<T>(value))
    }

    static getItem<T>(key: ReturnType<() => keyof typeof SessionStorageServiceKeys>): T | null {
        return storageUtils.getStorageItemValue<T>(sessionStorage.getItem(key))
    }

    static removeItem(key: ReturnType<() => keyof typeof SessionStorageServiceKeys>): void {
        sessionStorage.removeItem(key)
    }

    static clear(): void {
        sessionStorage.clear()
    }
}

export { SessionStorageService, SessionStorageServiceKeys }
