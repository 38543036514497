import { useCallback } from "react"

import { type UseQueryOptions, type UseQueryResult, useQuery } from "@tanstack/react-query"
import type { AxiosError } from "axios"

import { ServerStateKeys } from "@/constants"
import type { IModuleDto as IDto } from "@/pages/participant-dashboard/api/dto"
import type { IModuleModel as IModel } from "@/pages/participant-dashboard/models"

import { moduleByIdQueryAdapter as adapter } from "./module-by-id-query.adapter"
import { moduleByIdQueryFetcher as fetcher } from "./module-by-id-query.fetcher"

type TParams = UseQueryOptions & { id: number }
type TQuery = UseQueryResult<IModel, AxiosError>
type TReturn = ReturnType<(params: TParams) => TQuery>

function useHook(params: TParams): TReturn {
    return useQuery<IDto, AxiosError, IModel>([ServerStateKeys.ModuleById, params.id], useCallback(fetcher, []), {
        // @ts-expect-error no issue here, react-query types mess
        select: useCallback(adapter, []),
        keepPreviousData: true,
        refetchOnMount: true,
        ...params
    })
}

export { useHook as useModuleByIdQuery, type TQuery as TModuleByIdQuery }
