import { type FC, type ReactElement } from "react"

import { ParticipantLayout as Layout } from "@/layouts"
import { LeftSidebar as Sidebar } from "@/shared/participant-left-sidebar"
import { ESidebarTag } from "@/shared/sidebar/Sidebar"

import { ParticipantDashboardContainer as Container } from "."

const Page: FC = (): ReactElement => (
    <Layout tag="main" leftSidebar={<Sidebar tag={ESidebarTag.Aside} />}>
        <Container />
    </Layout>
)

Page.displayName = "ParticipantDashboardPage"

export { Page as ParticipantDashboardPage }
