import { isEmpty } from "$/utils/gates"

import { EModulePhaseOrChapterStatus, type TModulePhaseOrChapterStatusDto } from "@/3514/types"
import { getModulePhaseProgressEnumFromType, getModulePhaseTrackStatusEnumFromType } from "@/3514/utils"

import {
    EModulePhaseOrChapterUiStatus,
    type IModuleModel,
    type IModulePhaseChapterModel,
    type IModulePhaseModel
} from "../../../models"
import type { IModuleDto, IModulePhaseChapterDto, IModulePhaseDto, TModulePhaseOrChapterUiStatusDto } from "../../dto"

function _getModulePhaseOrChapterStatusEnumFromType(type: TModulePhaseOrChapterStatusDto): EModulePhaseOrChapterStatus {
    switch (type) {
        case "queued":
            return EModulePhaseOrChapterStatus.Queued
        case "in_progress":
            return EModulePhaseOrChapterStatus.InProgress
        case "complete":
            return EModulePhaseOrChapterStatus.Complete
        case "open":
            return EModulePhaseOrChapterStatus.Open
        case null:
        default:
            return null
    }
}

function _getModulePhaseOrChapterUiStatusEnumFromType(
    type: TModulePhaseOrChapterUiStatusDto
): EModulePhaseOrChapterUiStatus {
    switch (type) {
        case "current":
            return EModulePhaseOrChapterUiStatus.Current
        case "completed":
            return EModulePhaseOrChapterUiStatus.Completed
        case "completed_in_previous_phase":
            return EModulePhaseOrChapterUiStatus.CompletedInPreviousPhase
        case "completed_in_current_phase":
            return EModulePhaseOrChapterUiStatus.CompletedInCurrentPhase
        case "locked":
            return EModulePhaseOrChapterUiStatus.Locked
        case "not_started":
            return EModulePhaseOrChapterUiStatus.NotStarted
        case null:
        default:
            return null
    }
}

function adapter(dto: IModuleDto): IModuleModel {
    if (isEmpty(dto)) return null

    return {
        id: dto.id,
        title: dto.title,
        rank: dto.rank,
        phases: isEmpty(dto.phases)
            ? []
            : dto.phases.reduce<IModulePhaseModel[]>(
                  (
                      acc: IModulePhaseModel[],
                      {
                          id,
                          order,
                          phase_due_stamp: dateEnd,
                          phase_opens_stamp: dateStart,
                          track_status,
                          status,
                          phase_progress_bar,
                          chapters,
                          custom_status
                      }: IModulePhaseDto
                  ): IModulePhaseModel[] => {
                      const phaseModel: IModulePhaseModel = {
                          id,
                          order,
                          dateEnd,
                          dateStart,
                          progressStatus: getModulePhaseProgressEnumFromType(phase_progress_bar),
                          trackStatus: getModulePhaseTrackStatusEnumFromType(track_status),
                          status: _getModulePhaseOrChapterStatusEnumFromType(status),
                          uiStatus: _getModulePhaseOrChapterUiStatusEnumFromType(custom_status),
                          chapters: isEmpty(chapters)
                              ? []
                              : chapters.reduce<IModulePhaseChapterModel[]>(
                                    (
                                        chapterAcc: IModulePhaseChapterModel[],
                                        {
                                            id,
                                            title_data: title,
                                            order,
                                            status: cStatus,
                                            summary,
                                            chapter_progress: progress,
                                            chapter_type: cType,
                                            custom_status: cCustomStatus
                                        }: IModulePhaseChapterDto
                                    ): IModulePhaseChapterModel[] => {
                                        chapterAcc.push({
                                            id,
                                            title,
                                            order,
                                            summary,
                                            progress,
                                            status: _getModulePhaseOrChapterStatusEnumFromType(cStatus),
                                            uiStatus: _getModulePhaseOrChapterUiStatusEnumFromType(cCustomStatus),
                                            type: isEmpty(cType)
                                                ? null
                                                : {
                                                      id: cType?.id ?? Number(),
                                                      internalName: cType?.internal_name ?? String(),
                                                      slug: cType?.slug ?? String(),
                                                      title: cType?.title_data
                                                  }
                                        })

                                        return chapterAcc
                                    },
                                    []
                                )
                      }

                      acc.push(phaseModel)

                      return acc
                  },
                  []
              )
    }
}

export { adapter as moduleByIdQueryAdapter }
