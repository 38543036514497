import classNames from "classnames"

import { ETypographyColor, ETypographySize, typographyColorsClassMap, typographySizesClassMap } from "@/3514/components"

import { EInputVariant } from "./input.types"

const variantClassesMap: {
    [V in EInputVariant]: (textSize: ETypographySize, textColor: ETypographyColor, isDisabled: boolean) => string
} = {
    [EInputVariant.Default]: (textSize: ETypographySize, textColor: ETypographyColor, isDisabled: boolean): string =>
        classNames(
            isDisabled && "cursor-not-allowed",
            { [typographySizesClassMap[textSize]]: textSize },
            { [typographyColorsClassMap[textColor]]: textColor }
        )
}

const config = { variantClassesMap }

export { config as inputConfig }
