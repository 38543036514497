import { type ComponentProps, type FC, type ReactElement, type ReactNode, isValidElement } from "react"

import classNames from "classnames"
import { Link as RouterLink } from "react-router-dom"

import { ETypographyColor, ETypographyFontWeight, ETypographySize, ETypographyTag, Typography } from ".."

type TProps = ComponentProps<typeof RouterLink> & { children: string | ReactNode; className?: string }

const Component: FC<TProps> = ({ to, children, className = String(), ...props }: TProps): ReactElement => (
    <RouterLink to={to} {...props} className={classNames("group", { [className]: className })}>
        {isValidElement(children) ? (
            children
        ) : (
            <Typography
                tag={ETypographyTag.Span}
                size={ETypographySize.Small}
                weight={ETypographyFontWeight.Bold}
                color={ETypographyColor.Accent}
                className={classNames([
                    "transition-all",
                    "duration-200",
                    "ease-in-out",
                    "underline",
                    "decoration-accent",
                    "decoration-[2px]",
                    "underline-offset-[10px]",
                    "group-focus:underline-offset-[13px]",
                    "group-hover:underline-offset-[12px]"
                ])}
                text={children}
            />
        )}
    </RouterLink>
)

Component.displayName = "Link"

export { Component as Link }
