type TLikertQuestionOption = {
    id: number
    label?: string
    isFreeResponseAllowed?: boolean
    freeResponsePrompt?: string
}

type TLikertQuestion = {
    id: number
    question: string
    order: number
    options: TLikertQuestionOption[]
    isNotApplicable?: boolean
    notApplicableInput?: string
}

enum ELikertColorVariant {
    Colored = "colored",
    Default = "default"
}

enum ELikertColorSeverity {
    Success = "success",
    Warning = "warning",
    Danger = "danger"
}

enum ELikertOptionState {
    Default = "default",
    ActionAffected = "actionAffected",
    Selected = "selected"
}

enum ELikertOptionPosition {
    Left = "left",
    Middle = "middle",
    Right = "right"
}

enum ELikertOptionSize {
    MinWidth = "min",
    FullWidth = "fullWidth"
}

export type { TLikertQuestion, TLikertQuestionOption }
export { ELikertColorVariant, ELikertOptionState, ELikertColorSeverity, ELikertOptionPosition, ELikertOptionSize }
