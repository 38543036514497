enum ESpinnerSize {
    Small = "small",
    Medium = "medium",
    Large = "large"
}

enum ESpinnerVariant {
    Accent = "accent",
    White = "white"
}

export { ESpinnerSize, ESpinnerVariant }
