import { type ComponentType, type FC, type LazyExoticComponent, lazy } from "react"

import CoachingSessions from "@/components/coaching-sessions/CoachingSessions"
import Diagnostic from "@/components/diagnostic/Diagnostic"
import GroupInfo from "@/components/group-info/GroupInfo"
import Login from "@/components/login/Login"
import Offboarding from "@/components/offboarding/Offboarding"
import CoachSelectStep from "@/components/onboarding/CoachSelectStep"
import DiagnosticStep from "@/components/onboarding/DiagnosticStep"
import DiagnosticResponsesStep from "@/components/onboarding/DiagnosticeResponsesStep"
import ModuleListStep from "@/components/onboarding/ModuleListStep"
import OnBoardingProfileSetup from "@/components/onboarding/OnBoardingProfileSetup"
import OnBoardingRedirect from "@/components/onboarding/OnBoardingRedirect"
import ReflectionObjectiveStep from "@/components/onboarding/ReflectionObjectiveStep"
import SessionHoldTimesStep from "@/components/onboarding/SessionHoldTimesStep"
import VideoStep from "@/components/onboarding/VideoStep"
import { StepUrls, onboardingProfileSetupURL } from "@/components/onboarding/constants"
import ParticipantSessions from "@/components/participant-session/ParticipantSession"
import ParticipantWorks from "@/components/participant-works/ParticipantWorks"
import Profile from "@/components/profile/Profile"
import PostJourneyRedirect from "@/components/redirect-post-journey/PostJourneyRedirect"
import SetPassword from "@/components/set-password/SetPassword"
import WaitingListPage from "@/components/waiting-list/WaitingListPage"
import { AuthUrls, CoachUrls, ParticipantUrls } from "@/constants"
import {
    AssignModulePage,
    CoachAvailabilityPage,
    CoachCalendarsPage,
    CoachDashboardPage,
    CoachItineraryPage,
    CoachRatingPage,
    CoachRelinkAccountsPage,
    CoachSettingsAvailabilityStepPage,
    CoachSettingsCalendarAddStepPage,
    CoachSettingsConfirmAvailabilityStepPage,
    CoachSettingsLinkAccountStepPage,
    CoachSettingsLinkCalendarStepPage,
    CoachWelcomePage,
    CoachZoomAccountPage,
    CoachingPlanPage, // ParticipantDashboardPage,
    SimulabChatPage,
    SimulabCoacheesPage,
    StakeholderSurveyPage,
    SurveysPage,
    SwapSessionPage
} from "@/pages"
import { CoachCalendarStepUrls as CoachCalendarStep, CoachSettingsUrls } from "@/pages/coach-settings/constants"
import { ErrorPage } from "@/shared/error-page/ErrorPage"

const ParticipantDashboardPage: LazyExoticComponent<FC> = lazy(
    (): Promise<{ default: FC }> =>
        import("@/pages/participant-dashboard").then(({ ParticipantDashboardPage: D }): { default: FC } => ({
            default: D
        }))
)
const ParticipantChapterPage: LazyExoticComponent<FC> = lazy(
    (): Promise<{ default: FC }> =>
        import("@/pages/participant-chapter").then(({ ParticipantChapterPage: C }): { default: FC } => ({
            default: C
        }))
)

type Route = {
    path: string
    Component: ComponentType<any>
    order?: number
}

const participantRoutes: Route[] = [
    {
        path: onboardingProfileSetupURL,
        Component: OnBoardingProfileSetup
    },
    {
        path: StepUrls.VIDEO_STEP,
        Component: VideoStep
    },
    {
        path: StepUrls.MODULE_LIST_STEP,
        Component: ModuleListStep
    },
    {
        path: StepUrls.DIAGNOSTIC_STEP,
        Component: DiagnosticStep
    },
    {
        path: StepUrls.DIAGNOSTIC_RESPONSE_STEPS,
        Component: DiagnosticResponsesStep
    },
    {
        path: StepUrls.REFLECTION_OBJECTIVE_STEP,
        Component: ReflectionObjectiveStep
    },
    {
        path: StepUrls.SESSION_HOLD_TIMES_STEP,
        Component: SessionHoldTimesStep
    },
    {
        path: StepUrls.ONBOARDING_REDIRECT_PAGE,
        Component: OnBoardingRedirect
    },
    {
        path: StepUrls.COACH_SELECT_STEP,
        Component: CoachSelectStep
    },
    {
        path: ParticipantUrls.COACH_RATING,
        Component: CoachRatingPage
    },
    {
        path: ParticipantUrls.DIAGNOSTIC,
        Component: Diagnostic
    },
    {
        path: ParticipantUrls.WAITING_LIST,
        Component: WaitingListPage
    },
    {
        path: ParticipantUrls.WAITING_LIST_SSO,
        Component: WaitingListPage
    },
    {
        path: ParticipantUrls.SESSION_LIST,
        Component: CoachingSessions
    },
    {
        path: ParticipantUrls.SURVEYS,
        Component: SurveysPage
    },
    {
        path: ParticipantUrls.SWAP_SESSION,
        Component: SwapSessionPage
    },
    {
        path: ParticipantUrls.OFFBOARDING,
        Component: Offboarding
    },
    {
        path: ParticipantUrls.PARTICIPANT_REDIRECT_URL_PAGE,
        Component: PostJourneyRedirect
    },
    {
        path: ParticipantUrls.PROFILE,
        Component: Profile
    },
    {
        path: ParticipantUrls.COACHING_PLAN,
        Component: CoachingPlanPage
    },
    {
        path: ParticipantUrls.COACHEES,
        Component: SimulabCoacheesPage
    },
    {
        path: ParticipantUrls.SIMULAB_CHAT,
        Component: SimulabChatPage
    },
    {
        path: ParticipantUrls.ASSIGN_MODULE,
        Component: AssignModulePage
    },
    {
        path: "/error",
        Component: ErrorPage
    },
    {
        path: ParticipantUrls.DASHBOARD,
        Component: ParticipantDashboardPage
    },
    {
        path: ParticipantUrls.DASHBOARD_MODULE,
        Component: ParticipantDashboardPage
    },
    {
        path: ParticipantUrls.CHAPTER,
        Component: ParticipantChapterPage
    }
]

const coachCalendarSetupRoutes: Route[] = [
    {
        path: CoachCalendarStep.LINK_CALENDAR_STEP,
        Component: CoachSettingsLinkCalendarStepPage,
        order: 1
    },
    {
        path: CoachCalendarStep.LINK_ACCOUNT_STEP,
        Component: CoachSettingsLinkAccountStepPage,
        order: 2
    },
    {
        path: CoachCalendarStep.AVAILABILITY_STEP,
        Component: CoachSettingsAvailabilityStepPage,
        order: 3
    },
    {
        path: CoachCalendarStep.AVAILABILITY_CONFIRM_STEP,
        Component: CoachSettingsConfirmAvailabilityStepPage,
        order: 4
    }
]

const coachRoutes: Route[] = [
    {
        path: CoachUrls.WELCOME,
        Component: CoachWelcomePage
    },
    {
        path: CoachUrls.ITINERARY,
        Component: CoachItineraryPage
    },
    {
        path: CoachUrls.PARTICIPANTS_LIST,
        Component: CoachDashboardPage
    },
    {
        path: CoachUrls.SESSIONS_LIST,
        Component: CoachDashboardPage
    },
    {
        path: CoachUrls.GROUP_LIST,
        Component: CoachDashboardPage
    },
    {
        path: CoachUrls.PARTICIPANT_WORKS,
        Component: ParticipantWorks
    },
    {
        path: CoachUrls.GROUP_INFO,
        Component: GroupInfo
    },
    {
        path: CoachUrls.PARTICIPANT_COACHING_PLAN,
        Component: CoachingPlanPage
    },
    {
        path: CoachUrls.INDIVIDUAL_SESSIONS,
        Component: ParticipantSessions
    },
    {
        path: CoachCalendarStep.CALENDAR_ADD,
        Component: CoachSettingsCalendarAddStepPage
    },
    {
        path: CoachSettingsUrls.CALENDARS,
        Component: CoachCalendarsPage
    },
    {
        path: CoachSettingsUrls.AVAILABILITY,
        Component: CoachAvailabilityPage
    },
    {
        path: CoachSettingsUrls.ZOOM_ACCOUNT,
        Component: CoachZoomAccountPage
    },
    { path: CoachCalendarStep.RELINK_ACCOUNTS, Component: CoachRelinkAccountsPage },
    ...coachCalendarSetupRoutes
]

const authRoutes: Route[] = [
    {
        path: AuthUrls.SET_PASSWORD,
        Component: SetPassword
    },
    {
        path: AuthUrls.RESET_PASSWORD,
        Component: SetPassword
    },
    {
        path: AuthUrls.LOGIN,
        Component: Login
    }
]

const unauthorizedPages: Route[] = [
    {
        path: "/survey/:access_code?",
        Component: StakeholderSurveyPage
    }
]

export { authRoutes, participantRoutes, coachRoutes, unauthorizedPages, coachCalendarSetupRoutes }
