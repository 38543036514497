import classNames from "classnames"

import { ETypographyColor, ETypographySize, typographyColorsClassMap, typographySizesClassMap } from "../typography"

import { ETextAreaVariant } from "./textarea.types"

const _baseClasses: string[] = [
    "bg-white",
    "w-full",
    "border-[2px]",
    "border-blue-400",
    "rounded-[10px]",
    "px-[15px]",
    "py-[10px]",
    "min-h-[45px]",
    "hover:border-blue-300",
    "focus:border-blue-300",
    "active:border-blue-300",
    "transition-all",
    "duration-200"
]

const variantClassesMap: {
    [V in ETextAreaVariant]: (
        textSize: ETypographySize,
        textColor: ETypographyColor,
        isDisabled: boolean,
        placeholderTextColor: ETypographyColor,
        placeholderTextSize: ETypographySize
    ) => string
} = {
    [ETextAreaVariant.Default]: (
        textSize: ETypographySize,
        textColor: ETypographyColor,
        isDisabled: boolean,
        placeholderTextColor: ETypographyColor,
        placeholderTextSize: ETypographySize
    ): string =>
        classNames(
            _baseClasses,
            isDisabled && "cursor-not-allowed",
            { [typographySizesClassMap[textSize]]: textSize },
            { [typographyColorsClassMap[textColor]]: textColor },
            `placeholder:${typographySizesClassMap[placeholderTextSize]}`,
            `placeholder:${typographyColorsClassMap[placeholderTextColor]}`
        )
}

const config = { variantClassesMap }

export { config as textareaConfig }
