import { type FC, type LazyExoticComponent, lazy } from "react"

const AnalyticsModal: LazyExoticComponent<FC> = lazy(
    (): Promise<{ default: FC }> =>
        import("./participant-analytics").then(({ ParticipantAnalyticsModalContainer: A }): { default: FC } => ({
            default: A
        }))
)

const SessionAttendanceModal: LazyExoticComponent<FC> = lazy(
    (): Promise<{ default: FC }> =>
        import("./participant-session-attendance").then(
            ({ ParticipantSessionAttendanceModalContainer: S }): { default: FC } => ({
                default: S
            })
        )
)

export { SessionAttendanceModal, AnalyticsModal }
