import {
    type BaseSyntheticEvent,
    type ChangeEvent,
    type Dispatch,
    type FC,
    Fragment,
    type KeyboardEvent,
    type MutableRefObject,
    type ReactElement,
    type SetStateAction,
    useId,
    useRef,
    useState
} from "react"

import classNames from "classnames"
import { CSSTransition } from "react-transition-group"

import useMediaQuery from "$/hooks/use-media-query"

import {
    Checkbox,
    ELikertColorVariant,
    ETypographyAlignment,
    ETypographyColor,
    ETypographySize,
    ETypographyTag,
    type ILikertContextType,
    TextArea,
    Typography,
    useLikertContext
} from "@/3514/components"
import { ELikertFormField, ILikertFormQuestion } from "@/3514/components/likert/likert.context"
import { MAX_WIDTH_MOBILE_MEDIA } from "@/constants"

import { type TLikertQuestion, type TLikertQuestionOption } from "../likert.types"
import { likertUtils as utils } from "../likert.utils"

import { LikertOption } from "./likert-option.component"

const { getLikertOptionPositionInList: getOptionPosition } = utils

type TProps = Omit<TLikertQuestion, "order"> & { textareaAnimationClass: string }

const Row: FC<TProps> = ({
    question,
    options,
    notApplicableInput,
    isNotApplicable,
    id: rowId,
    textareaAnimationClass
}: TProps): ReactElement => {
    const rowKey: string = useId()

    const isMobile: boolean = useMediaQuery(MAX_WIDTH_MOBILE_MEDIA)

    const [selectedOptionIndex, setSelectedOptionIndex]: [number, Dispatch<SetStateAction<number>>] =
        useState<number>(Number())

    const optionLabelsArr: string[] = options.reduce<string[]>(
        (
            acc: string[],
            { label }: TLikertQuestionOption,
            idx: number,
            { length }: TLikertQuestionOption[]
        ): string[] => {
            if (!isMobile || idx === 0 || idx === length - 1) {
                acc.push(label)
            }
            return acc
        },
        []
    )

    const {
        colorVariant,
        formMethods: { setValue: setOptionValue, watch: watchOptionChange }
    }: ILikertContextType = useLikertContext()

    const formQuestion: ILikertFormQuestion = watchOptionChange(ELikertFormField.QuestionList).find(
        (q: ILikertFormQuestion): boolean => q.id === rowId
    )

    const handleAction: (e: BaseSyntheticEvent) => void = (e: BaseSyntheticEvent): void => {
        const index: number = Number(e.target.dataset.index)

        setSelectedOptionIndex((prev: number): number => (prev === index ? Number() : index))

        setOptionValue(`questionList.${formQuestion.index}.answer`, formQuestion.answer === index ? Number() : index, {
            shouldValidate: true,
            shouldDirty: true
        })
    }

    const handleKeyDown: (e: KeyboardEvent) => void = (e: KeyboardEvent): void => e.key === "Enter" && handleAction(e)

    const handleTextAreaChange: (e: ChangeEvent<HTMLTextAreaElement>) => void = (
        event: ChangeEvent<HTMLTextAreaElement>
    ): void =>
        setOptionValue(`questionList.${formQuestion.index}.freeResponsePrompt`, event.target.value as string, {
            shouldValidate: true,
            shouldDirty: true
        })

    const {
        isFreeResponseAllowed: isOptionFreeResponseAllowed,
        freeResponsePrompt: optionFreeResponsePrompt
    }: Partial<TLikertQuestionOption> = options[
        options?.findIndex((_o: TLikertQuestionOption, idx: number): boolean => idx + 1 === selectedOptionIndex)
    ] ?? { isFreeResponseAllowed: false, freeResponsePrompt: String() }

    const textareaWrapperRef: MutableRefObject<HTMLDivElement> = useRef<HTMLDivElement>()

    return (
        <div className="flex flex-col gap-y-[12px]">
            <Typography
                className="px-[10px]"
                text={question}
                tag={ETypographyTag.Paragraph}
                size={ETypographySize.Small}
                color={ETypographyColor.Dark}
            />

            <div className="flex flex-col gap-y-[5px]">
                <div
                    onKeyDown={handleKeyDown}
                    onClick={handleAction}
                    tabIndex={-1}
                    role="tablist"
                    className={classNames(
                        "inline-flex gap-x-[5px] p-[3px] rounded-[13px] border-[2px] border-blue-400 border-solid",
                        colorVariant === ELikertColorVariant.Default && "bg-blue-600"
                    )}
                >
                    {options.map(
                        (
                            { id, label }: TLikertQuestionOption,
                            idx: number,
                            { length: arrLength }: TLikertQuestionOption[]
                        ): ReactElement => (
                            <Fragment key={`${rowKey}-o${id}-${idx}`}>
                                <LikertOption
                                    label={label}
                                    isSelected={
                                        watchOptionChange(`questionList.${formQuestion.index}.answer`) === idx + 1
                                    }
                                    position={getOptionPosition(idx, arrLength)}
                                    dataIndex={String(idx + 1)}
                                />
                            </Fragment>
                        )
                    )}
                </div>

                <div className="inline-flex gap-x-[5px] w-full">
                    {optionLabelsArr.map(
                        (item: string, idx: number, { length }: string[]): ReactElement => (
                            <Fragment key={`${rowKey}-l-${item}-${idx}`}>
                                <Typography
                                    tag={ETypographyTag.Span}
                                    size={ETypographySize.ExtraSmall}
                                    color={ETypographyColor.DarkGray}
                                    alignment={
                                        isMobile && idx === 0
                                            ? ETypographyAlignment.Left
                                            : isMobile && idx === length - 1
                                              ? ETypographyAlignment.Right
                                              : ETypographyAlignment.Center
                                    }
                                    className={classNames(
                                        `w-full select-none block max-w-[${100 / length}%]`,
                                        idx === 0 && isMobile && "pl-[5px]",
                                        idx === length - 1 && isMobile && "pr-[5px]"
                                    )}
                                    text={item! ?? String()}
                                />
                            </Fragment>
                        )
                    )}
                </div>
            </div>

            <CSSTransition
                nodeRef={textareaWrapperRef}
                classNames={textareaAnimationClass}
                timeout={250}
                in={isOptionFreeResponseAllowed}
                mountOnEnter
                unmountOnExit
            >
                <div className="px-[10px] w-full" ref={textareaWrapperRef}>
                    <TextArea placeholder={optionFreeResponsePrompt} onChange={handleTextAreaChange} />
                </div>
            </CSSTransition>

            {isNotApplicable && (
                <div className="flex items-center mx-auto gap-x-[5px]">
                    <Checkbox ariaLabel={notApplicableInput ?? "This doesn't apply to me"} />
                    <Typography
                        text={notApplicableInput ?? "This doesn't apply to me"}
                        tag={ETypographyTag.Span}
                        size={ETypographySize.ExtraSmall}
                        color={ETypographyColor.DarkGray}
                    />
                </div>
            )}
        </div>
    )
}

Row.displayName = "LikertQuestionRow"

export { Row as LikertQuestionRow }
