import { useCallback } from "react"

import { type MutationOptions, type UseMutationResult, useMutation } from "@tanstack/react-query"
import type { AxiosError, AxiosResponse } from "axios"

import { EParticipantCoachingMomentChatApiMutationKey as EMutationKey } from "../../../config"

import { createCoachingMomentConversationFetcher as fetcher } from "./create-coaching-moment-conversation.fetcher"

type TParams = {
    component: number
    chapter_progress: number
    seed_answers: { question_uuid: string; answer: string }[]
}

type TResponse = AxiosResponse<{
    id: number
    status: string
    completed_stamp: null | string | undefined
    summary: null | string
}>

type TMutation = UseMutationResult<TResponse>

function useHook(options?: MutationOptions): ReturnType<() => TMutation> {
    return useMutation<TResponse, AxiosError, TParams>(
        [EMutationKey.CreateCoachingMomentChatConversation],
        useCallback(fetcher, []),
        // @ts-expect-error react-query types mess here, no error
        { ...options }
    )
}

export {
    useHook as useCreateCoachingMomentConversationMutation,
    type TMutation as TCreateCoachingMomentConversationMutation,
    type TParams as TCreateCoachingMomentConversationMutationParams,
    type TResponse as TCreateCoachingMomentConversationMutationResponse
}
