import {
    type ChangeEvent,
    type ForwardRefExoticComponent,
    type ForwardedRef,
    type PropsWithoutRef,
    type ReactElement,
    type RefAttributes,
    forwardRef
} from "react"

import classNames from "classnames"

import { ETypographyColor, ETypographySize } from "@/3514/components"

import { inputConfig } from "./input.config"
import { EInputVariant } from "./input.types"

type TProps = {
    defaultValue?: string
    value?: string
    onChange?(event?: ChangeEvent<HTMLInputElement>): void
    placeholder?: string
    className?: string
    variant?: EInputVariant
    textSize?: ETypographySize
    textColor?: ETypographyColor
    isDisabled?: boolean
}

const Component: ForwardRefExoticComponent<PropsWithoutRef<TProps> & RefAttributes<HTMLInputElement>> = forwardRef<
    HTMLInputElement,
    TProps
>(
    (
        {
            defaultValue,
            onChange,
            placeholder,
            className = String(),
            variant = EInputVariant.Default,
            textSize = ETypographySize.Medium,
            textColor = ETypographyColor.DarkGray,
            isDisabled = false,
            ...rest
        }: TProps,
        ref: ForwardedRef<HTMLInputElement>
    ): ReactElement => (
        <input
            {...rest}
            ref={ref}
            tabIndex={0}
            aria-disabled={isDisabled}
            disabled={isDisabled}
            className={classNames(
                { [className]: className },
                { [inputConfig.variantClassesMap[variant](textSize, textColor, isDisabled)]: variant }
            )}
            defaultValue={defaultValue}
            onChange={onChange}
            placeholder={placeholder}
        />
    )
)
Component.displayName = "Input"

export { Component as Input }
