import { type FC, type ReactElement } from "react"

import classNames from "classnames"

import { type TUseManualHover, useManualHover } from "@/hooks"

import { likertConfig as config } from "../likert.config"
import { type ILikertContextType, useLikertContext } from "../likert.context"
import { ELikertOptionPosition, type TLikertQuestionOption } from "../likert.types"
import { likertUtils as utils } from "../likert.utils"

const {
    baseOptionClasses,
    baseIndicatorClasses,
    optionPositionMap: positionMap,
    optionColorMap: colorMap,
    optionSizeMap: sizeMap
} = config
const { getLikertOptionState: getState, getLikertOptionSeverity: getSeverity } = utils

type TProps = Omit<TLikertQuestionOption, "id"> & {
    dataIndex?: string
    isSelected?: boolean
    position: ELikertOptionPosition
}

const Option: FC<TProps> = ({
    label,
    dataIndex,
    isSelected = false,
    position = ELikertOptionPosition.Middle
}: TProps): ReactElement => {
    const { isActionAffected, onMouseEnter, onMouseLeave, onTouchStart, onTouchEnd, onClick }: TUseManualHover =
        useManualHover()

    const { colorVariant, optionSize: size }: ILikertContextType = useLikertContext()

    const { option: getOptionClasses, indicator: getIndicatorClasses } = colorMap[colorVariant](
        getState(isActionAffected, isSelected),
        getSeverity(colorVariant)
    )

    const optionClasses: string = getOptionClasses()
    const indicatorClasses: string = getIndicatorClasses()

    return (
        <div
            role="tab"
            aria-selected={isSelected}
            aria-label={label! || `Likert Option ${dataIndex}`}
            tabIndex={0}
            onTouchStart={onTouchStart}
            onTouchEnd={onTouchEnd}
            onMouseLeave={onMouseLeave}
            onClick={onClick}
            onMouseEnter={onMouseEnter}
            data-index={dataIndex}
            className={classNames(baseOptionClasses, {
                [optionClasses]: optionClasses,
                [sizeMap[size]]: size,
                [positionMap[position]]: position
            })}
        >
            <i className={classNames(baseIndicatorClasses, { [indicatorClasses]: indicatorClasses })} />
        </div>
    )
}

Option.displayName = "LikertOption"

export { Option as LikertOption }
