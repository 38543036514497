import { jwtDecode } from "jwt-decode"
import { DateTime } from "luxon"

import { EStorageServiceType, LocalStorageServiceKeys, SessionStorageServiceKeys, StorageService } from "@/services"

import { isNullOrUndefined } from "./gates"

const localStorageService: StorageService = new StorageService(EStorageServiceType.Local)
const sessionStorageService: StorageService = new StorageService(EStorageServiceType.Session)

function getCurrentAccessToken(): string {
    let token: string = sessionStorageService.getItem<string>(SessionStorageServiceKeys.FakeParticipantTokenAccess())

    if (isNullOrUndefined(token)) {
        token = localStorageService.getItem<string>(LocalStorageServiceKeys.TokenAccess())
    }

    return token
}

function getCurrentRefreshToken(): string {
    let token: string = sessionStorageService.getItem<string>(SessionStorageServiceKeys.FakeParticipantTokenRefresh())

    if (isNullOrUndefined(token)) {
        token = localStorageService.getItem<string>(LocalStorageServiceKeys.TokenRefresh())
    }

    return token
}

function setCurrentAccessToken(accessToken: string): void {
    if (accessToken) {
        if (sessionStorageService.getItem(SessionStorageServiceKeys.FakeParticipantsFeatureEnabled()))
            sessionStorageService.setItem(SessionStorageServiceKeys.FakeParticipantTokenAccess(), accessToken)
        else localStorageService.setItem(LocalStorageServiceKeys.TokenAccess(), accessToken)
    }
}

function setCurrentRefreshToken(refreshToken: string): void {
    if (refreshToken) {
        if (sessionStorageService.getItem(SessionStorageServiceKeys.FakeParticipantsFeatureEnabled()))
            sessionStorageService.setItem(SessionStorageServiceKeys.FakeParticipantTokenRefresh(), refreshToken)
        else localStorageService.setItem(LocalStorageServiceKeys.TokenRefresh(), refreshToken)
    }
}

function _base64UrlDecode(str: string): string {
    try {
        return decodeURIComponent(
            atob(str.replace(/-/g, "+").replace(/_/g, "/"))
                .split("")
                .map((c: string): string => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
                .join("")
        )
    } catch (e) {
        return ""
    }
}

function isTokenNotCorrupted(token: string): boolean {
    const parts: string[] = token.split(".")

    if (parts.length !== 3) return false

    const header: string = _base64UrlDecode(parts[0])
    const payload: string = _base64UrlDecode(parts[1])

    try {
        JSON.parse(header)
        JSON.parse(payload)
        return true
    } catch (e) {
        return false
    }
}

function isTokenValidBasedOnExpirationTime(token: string): boolean {
    try {
        return DateTime.now().toSeconds() <= DateTime.fromSeconds(jwtDecode(token).exp).toSeconds()
    } catch (error) {
        return false
    }
}

function isAccessTokenValid(): boolean {
    const token: string = getCurrentAccessToken()

    return isTokenValidBasedOnExpirationTime(token) && isTokenNotCorrupted(token)
}

function isRefreshTokenValid(): boolean {
    const token: string = getCurrentRefreshToken()

    return isTokenValidBasedOnExpirationTime(token) && isTokenNotCorrupted(token)
}

export {
    isAccessTokenValid,
    isRefreshTokenValid,
    getCurrentAccessToken,
    getCurrentRefreshToken,
    setCurrentAccessToken,
    setCurrentRefreshToken
}
