import { type FC, type ReactElement } from "react"

import { ETypographyColor, ETypographyFontWeight, ETypographySize, ETypographyTag, Typography } from "@/3514/components"
import { type IUseTranslation, useTranslation } from "@/hooks"

const classes = {
    container: [
        "absolute",
        "w-full",
        "rounded-tl-[10px]",
        "rounded-tr-[10px]",
        "top-[0px]",
        "left-[0px]",
        "bg-chat-title",
        "flex",
        "gap-x-[10px]",
        "pt-[15px]",
        "pb-[10px]",
        "px-[15px]",
        "backdrop-blur-[2px]",
        "z-[1]"
    ].join(" "),
    badge: "py-[2px] px-[5px] bg-accent rounded-[5px]"
}

const Heading: FC = (): ReactElement => {
    const { t }: IUseTranslation = useTranslation()

    return (
        <div className={classes.container}>
            <Typography
                text={t("participantSide.chapter.coachingMoment.chat.topLabel")}
                color={ETypographyColor.White}
                size={ETypographySize.Small}
                weight={ETypographyFontWeight.Bold}
            />
            <Typography
                text="BETA"
                color={ETypographyColor.White}
                weight={ETypographyFontWeight.Bold}
                tag={ETypographyTag.Paragraph}
                size={ETypographySize.Tiny}
                className={classes.badge}
            />
        </div>
    )
}

Heading.displayName = "ParticipantCoachingMomentChatHeading"

export { Heading as ParticipantCoachingMomentChatHeading }
