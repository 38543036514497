import classNames from "classnames"

import { verticalProgressBarConfig } from "./vertical-progress-bar.config"
import type { TVerticalProgressBarPieceRenderConfig as TPieceRenderConfig } from "./vertical-progress-bar.types"

function getStarFillColor(isStarFilled: boolean): string {
    return isStarFilled ? verticalProgressBarConfig.VERTICAL_PROGRESS_BAR_STAR_FILLED_COLOR : "none"
}

function _getBaseClasses({
    isFilled,
    isAccessible,
    color
}: Pick<TPieceRenderConfig, "isAccessible" | "color" | "isFilled">): string {
    return [
        "relative",
        "w-full",
        isAccessible ? "cursor-pointer" : "cursor-not-allowed",
        isFilled ? color : "bg-gray-500"
    ].join(" ")
}

function generatePiecesClasses(): {
    firstPiece(config: TPieceRenderConfig): string
    secondPiece(config: TPieceRenderConfig): string
    thirdPiece(config: TPieceRenderConfig & { isBarFullHeight: boolean }): string
    thirdPieceBottom(config: TPieceRenderConfig): string
} {
    return {
        firstPiece: ({
            withoutStar,
            isHidden,
            isFilled,
            color,
            isAccessible,
            withBoldBorder
        }: TPieceRenderConfig): string =>
            classNames(
                "rounded-tl-[7px] rounded-tr-[7px] flex items-center justify-center mt-auto border-b-white",
                withBoldBorder ? "border-b-[2px]" : "border-b-[1px]",
                withoutStar ? "min-h-[35px]" : "min-h-[22px]",
                isHidden && "hidden",
                _getBaseClasses({ isAccessible, isFilled, color })
            ),
        secondPiece: ({ isHidden, isFilled, withBoldBorder, isAccessible, color }: TPieceRenderConfig): string =>
            classNames(
                "relative w-full h-full min-h-[35px] basis-[calc(50%-17px)] border-b-white",
                withBoldBorder ? "border-b-[2px]" : "border-b-[1px]",
                isHidden && "hidden",
                _getBaseClasses({ isAccessible, isFilled, color })
            ),
        thirdPiece: ({
            isHidden,
            isFilled,
            color,
            isAccessible,
            isBarFullHeight
        }: TPieceRenderConfig & { isBarFullHeight: boolean }): string =>
            classNames(
                isBarFullHeight
                    ? isHidden
                        ? "rounded-tl-[10px] rounded-tr-[10px] mt-auto"
                        : "min-h-[35px] h-full rounded-bl-[3px] rounded-br-[3px]"
                    : "flex min-h-[35px] h-full basis-[calc(50%-17px)] rounded-bl-[3px] rounded-br-[3px] mt-auto",
                _getBaseClasses({ isAccessible, isFilled, color })
            ),
        thirdPieceBottom: ({
            isFilled,
            isBottomRounded,
            color,
            isHidden,
            isBarFullHeight
        }: TPieceRenderConfig & { isBarFullHeight: boolean }): string =>
            classNames(
                "w-full",
                isHidden && "hidden",
                isBottomRounded && "rounded-br-[3px] rounded-bl-[3px]",
                isBarFullHeight ? "h-[15px]" : "h-[10px]",
                isFilled ? "rounded-tl-[10px] rounded-tr-[10px] absolute bottom-0 top-auto" : "h-full",
                {
                    [color]: color
                }
            )
    }
}

const utils = { getStarFillColor, generatePiecesClasses }

export { utils as verticalProgressBarUtils }
