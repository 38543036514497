import { useCallback } from "react"

import { type UseQueryOptions, type UseQueryResult, useQuery } from "@tanstack/react-query"
import type { AxiosError } from "axios"

import { EParticipantCoachingMomentChatApiQueryKey as EQueryKey } from "../../../config"
import type {
    IParticipantCoachingMomentChatConversationDto as IDto,
    IParticipantCoachingMomentChatConversationModel as IModel
} from "../../../types"

import { coachingMomentChatConversationQueryAdapter as adapter } from "./coaching-moment-chat-conversation.adapter"
import { coachingMomentChatConversationQueryFetcher as fetcher } from "./coaching-moment-chat-conversation.fetcher"

type TParams = UseQueryOptions & { conversationId: number }
type TQuery = UseQueryResult<IModel, AxiosError>
type TReturn = ReturnType<(params: TParams) => TQuery>

function useHook(params: TParams): TReturn {
    return useQuery<IDto, AxiosError, IModel, [EQueryKey, number]>(
        [EQueryKey.CoachingMomentChatConversation, params.conversationId],
        useCallback(fetcher, []),
        {
            // @ts-expect-error no issue here, react-query types mess
            select: useCallback(adapter, []),
            ...params
        }
    )
}

export { useHook as useCoachingMomentChatConversationQuery }
export type { TQuery as TCoachingMomentChatConversationQuery }
