import { Fragment, type ReactNode } from "react"

import { ESpecialChar } from "./special-char.types"

const SpecialChars: { [K in ESpecialChar]: ReactNode } = {
    [ESpecialChar.EmptySpace]: <Fragment>&nbsp;</Fragment>,
    [ESpecialChar.Comma]: <Fragment>&#x2C;</Fragment>,
    [ESpecialChar.Ampersand]: <Fragment>&amp;</Fragment>,
    [ESpecialChar.Hyphen]: <Fragment>&#x2D;</Fragment>,
    [ESpecialChar.Dot]: <Fragment>&#x2E;</Fragment>,
    [ESpecialChar.QuestionMark]: <Fragment>&#x3F;</Fragment>,
    [ESpecialChar.ExclamationMark]: <Fragment>&#33;</Fragment>,
    [ESpecialChar.Cross]: <Fragment>&#xD7;</Fragment>,
    [ESpecialChar.Colon]: <Fragment>&#58;</Fragment>,
    [ESpecialChar.Percent]: <Fragment>&#x25;</Fragment>,
    [ESpecialChar.CheckMark]: <Fragment>&#10004;</Fragment>,
    [ESpecialChar.OpeningBracket]: <Fragment>&#40;</Fragment>,
    [ESpecialChar.ClosingBracket]: <Fragment>&#41;</Fragment>
}

export { SpecialChars }
