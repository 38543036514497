import React, {
    type ComponentType,
    type Dispatch,
    type FC,
    type MutableRefObject,
    type ReactElement,
    type ReactNode,
    type SetStateAction,
    Suspense,
    type UIEvent,
    useCallback,
    useRef,
    useState
} from "react"

import classNames from "classnames"
import { Link } from "react-router-dom"

import { useOnClickOutside } from "$/hooks/use-outside-click"
import { getCurrentAccessToken, getCurrentRefreshToken } from "$/utils/tokens"

import "@/assets/coach.scss"
import { CARE_TEAM_EMAIL } from "@/constants"
import { type IAuthContext, type IStoreContext, useAuthContext, useStoreContext } from "@/context"
import { type IUseTranslation, type TUseLogoNavigation, useLogoNavigation, useTranslation } from "@/hooks"
import { CoachCalendarStepUrls, CoachSettingsUrls } from "@/pages/coach-settings/constants"
import { type ISubUserModel, useSubUserListQuery } from "@/pages/coach-welcome/api"
import { AVATAR_FALLBACK_COLOR } from "@/pages/coach-welcome/config"
import { LocalStorageServiceKeys, StorageService, type TStoredSubUser } from "@/services"
import ProductTypeLogo from "@/shared/ProductTypeLogo"
import Avatar from "@/shared/avatar/Avatar"
import { HeaderDropdownProfileList } from "@/shared/header-dropdown-profile-list"
import FullSpinner from "@/shared/spinner/FullSpinner"
import type { TEmptyCallback } from "@/shared/types/functions"
import { capitalized } from "@/utils/common"
import useRoles, { type TUseRoles } from "@/utils/hooks/use-roles"

interface IProps {
    leftSidebar?: ComponentType | JSX.Element | null
    headerTitle?: ReactNode
    nav?: ComponentType | null
    wrapperExtraClass?: string
    showLogo?: boolean
    navItem?: ComponentType | JSX.Element | null
    align?: "center" | "left"
    size?: "default" | "max-md"
    pageTitle?: ReactNode
    fullWidth?: boolean
    withContainer?: boolean
    children: ReactNode | ReactNode[]
    withoutFooter?: boolean
    withAccessibleDropdown?: boolean
}

const storageService: StorageService = new StorageService()

const CoachLayout: FC<IProps> = ({
    children,
    leftSidebar,
    pageTitle = null,
    wrapperExtraClass = "",
    showLogo = true,
    align = "left",
    withContainer = true,
    headerTitle = null,
    nav = null,
    navItem = null,
    size = "default",
    fullWidth = false,
    withoutFooter = false,
    withAccessibleDropdown = false
}: IProps): ReactElement => {
    const { t }: IUseTranslation = useTranslation()

    const { logout, user }: IAuthContext = useAuthContext()
    const { isOpenBurgerMenu }: IStoreContext = useStoreContext()
    const { isCoach }: TUseRoles = useRoles()

    const [isActiveMenu, setIsActiveMenu]: [boolean, Dispatch<SetStateAction<boolean>>] = useState<boolean>(false)

    const outsideRefClick: MutableRefObject<HTMLDivElement> = useRef<HTMLDivElement>()
    const avatarRef: MutableRefObject<HTMLImageElement | SVGSVGElement> = useRef<HTMLImageElement | SVGSVGElement>(null)

    const toggleActiveMenu: TEmptyCallback = useCallback(
        (): void => setIsActiveMenu((prev: boolean): boolean => !prev),
        []
    )

    const handleLogout: (e: UIEvent) => void = useCallback(
        (e: UIEvent): void => {
            e.preventDefault()
            logout({
                onComplete: async (): Promise<void> =>
                    user?.hasSubUsers
                        ? storageService.setItem<boolean>(LocalStorageServiceKeys.MainUserLoggedOut(), true)
                        : void 0
            })
        },
        [user, logout]
    )

    const handleClickOutside: (e: MouseEvent) => void = useCallback(
        (e: MouseEvent): void => e.target !== avatarRef.current && setIsActiveMenu(false),
        []
    )

    const handleSubUserListQuerySuccess: (list: ISubUserModel[]) => void = useCallback(
        (subUserList: ISubUserModel[]): void => {
            storageService.removeItem(LocalStorageServiceKeys.SubUserList())

            const list: TStoredSubUser[] = []

            list.push({
                id: user?.userId ?? user?.id,
                jwtPair: {
                    access: getCurrentAccessToken(),
                    refresh: getCurrentRefreshToken()
                },
                role: capitalized(user.role),
                photo: user.photo,
                firstName: user?.firstName,
                lastName: user?.lastName,
                avatarColor: undefined,
                productType: undefined
            })

            subUserList?.forEach(
                ({ id, jwtPair, color, productType, photo, firstName, lastName }: ISubUserModel): void => (
                    list.push({
                        id,
                        jwtPair,
                        productType,
                        firstName,
                        lastName,
                        role: `${productType} User`,
                        avatarColor: color ?? AVATAR_FALLBACK_COLOR,
                        photo
                    }),
                    void 0
                )
            )

            storageService.setItem<TStoredSubUser[]>(LocalStorageServiceKeys.SubUserList(), list)
        },
        [user]
    )

    useSubUserListQuery({
        enabled: user?.hasSubUsers,
        onSuccess: handleSubUserListQuerySuccess
    })

    useOnClickOutside(outsideRefClick, handleClickOutside)

    const { logoNavigationUrl }: TUseLogoNavigation = useLogoNavigation()

    const avatarDimensions: number = user?.photo ? 30 : 26

    return (
        // @ts-expect-error legacy markup defect
        <div className={classNames("coach-wrapper", { [wrapperExtraClass]: wrapperExtraClass })}>
            {nav || (
                <nav className="coach-navbar navbar navbar-expand-lg justify-content-between">
                    {/* @ts-expect-error legacy markup defect */}
                    <div className="d-flex align-items-center">
                        {showLogo && (
                            <Link to={logoNavigationUrl} className="d-block">
                                <ProductTypeLogo />
                            </Link>
                        )}
                        {navItem}
                    </div>
                    <div className="navbar-container-screen">
                        <div className="coach-nav-header-title">{headerTitle}</div>
                        <div
                            id="navbar"
                            className={classNames("navbar-collapse ml-lg-auto", {
                                show: isOpenBurgerMenu
                            })}
                        >
                            <div className="header-nav-item header-avatar-item d-none d-lg-block position-relative">
                                <div
                                    className={classNames("dropdown header-nav-dropdown", {
                                        accessible: withAccessibleDropdown
                                    })}
                                >
                                    <button
                                        className="btn dropdown-toggle d-lg-inline-flex m-0"
                                        type="button"
                                        {...(withAccessibleDropdown && { tabIndex: 0 })}
                                        id="userDropdownButton"
                                        data-toggle="dropdown"
                                        aria-expanded={isActiveMenu}
                                        onClick={toggleActiveMenu}
                                    >
                                        <Avatar
                                            ref={avatarRef}
                                            width={avatarDimensions}
                                            height={avatarDimensions}
                                            url={user?.photo}
                                            alt={`photo ${user?.firstName}`}
                                        />
                                    </button>
                                </div>
                                <div
                                    className={classNames("dropdown-header-menu secondary", {
                                        show: isActiveMenu,
                                        "with-profile-list": user?.hasSubUsers
                                    })}
                                    ref={outsideRefClick}
                                >
                                    <a className="dropdown-header-item secondary" href={CARE_TEAM_EMAIL}>
                                        Contact Care Team
                                    </a>
                                    {isCoach && (
                                        <Link
                                            className="dropdown-header-item secondary"
                                            to={
                                                user.calendar_setup_confirmed
                                                    ? CoachSettingsUrls.AVAILABILITY
                                                    : CoachCalendarStepUrls.LINK_CALENDAR_STEP
                                            }
                                        >
                                            Calendar Settings
                                        </Link>
                                    )}
                                    <span className="dropdown-header-item secondary" onClick={handleLogout}>
                                        {t("Sign Out")}
                                    </span>
                                    {user?.hasSubUsers ? <HeaderDropdownProfileList /> : null}
                                </div>
                            </div>
                        </div>
                    </div>
                </nav>
            )}

            {leftSidebar}

            <main
                className={classNames("coach-main", {
                    "w-100": !leftSidebar,
                    "coach-main-s": !fullWidth && !!leftSidebar,
                    "with-aside-margin": fullWidth && !!leftSidebar
                })}
            >
                <div
                    className={classNames({
                        "container-max-md": size === "max-md",
                        container: withContainer && align === "left" && (!fullWidth || (leftSidebar && fullWidth)),
                        "container-center": align === "center"
                    })}
                >
                    {pageTitle && (
                        <div className="page-title">
                            <h1>{pageTitle}</h1>
                        </div>
                    )}
                    <Suspense fallback={<FullSpinner />}>{children}</Suspense>
                </div>

                {!withoutFooter && <footer className="footer" />}
            </main>
        </div>
    )
}

export { CoachLayout }
