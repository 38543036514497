type TParticipantCoachingMomentChatConversationStatus = "completed" | "in_progress"

enum EParticipantCoachingMomentChatConversationStatus {
    Completed = "completed",
    InProgress = "inProgress"
}

type TParticipantCoachingMomentChatMessageAuthor = "ai" | "participant"

enum EParticipantCoachingMomentChatMessageAuthor {
    AI = "ai",
    User = "user"
}

interface IParticipantCoachingMomentChatMessageDto {
    id: number
    conversation_id: number
    author_role: TParticipantCoachingMomentChatMessageAuthor
    message: string
    conversation: Omit<IParticipantCoachingMomentChatConversationDto, "messages">
}

interface IParticipantCoachingMomentChatMessageModel {
    id: number
    conversationId: number
    author: EParticipantCoachingMomentChatMessageAuthor
    message: string
    conversation: Omit<IParticipantCoachingMomentChatConversationModel, "messages">
}

interface IParticipantCoachingMomentChatConversationDto {
    completed_stamp: string
    id: number
    status: TParticipantCoachingMomentChatConversationStatus
    summary: string | null
    messages: IParticipantCoachingMomentChatMessageDto[]
}

interface IParticipantCoachingMomentChatConversationModel {
    completedStamp: string
    id: number
    status: EParticipantCoachingMomentChatConversationStatus
    summary: string | null
    messages: IParticipantCoachingMomentChatMessageModel[]
}

interface IParticipantCoachingMomentChatNewMessagesDto {
    errors: []
    messages: IParticipantCoachingMomentChatMessageDto[]
}

interface IParticipantCoachingMomentChatNewMessagesModel {
    errors: []
    messages: IParticipantCoachingMomentChatMessageModel[]
}

export {
    type IParticipantCoachingMomentChatMessageModel,
    type IParticipantCoachingMomentChatMessageDto,
    type IParticipantCoachingMomentChatConversationDto,
    type IParticipantCoachingMomentChatConversationModel,
    type TParticipantCoachingMomentChatConversationStatus,
    type TParticipantCoachingMomentChatMessageAuthor,
    type IParticipantCoachingMomentChatNewMessagesDto,
    type IParticipantCoachingMomentChatNewMessagesModel,
    EParticipantCoachingMomentChatConversationStatus,
    EParticipantCoachingMomentChatMessageAuthor
}
